import { useState, FC } from "react";
import styles from "./RouteStepSeparator.module.scss";
import i18n from "../../../../i18next";

interface RouteStepSeparatorProps {
  displaySteps: boolean;
  onClick: () => void;
}

const RouteStepSeparator: FC<RouteStepSeparatorProps> = ({
  displaySteps,
  onClick,
}) => {
  const [labelState, setLabelState] = useState(displaySteps);
  const option = labelState
    ? i18n.t("navigate_page.show_steps")
    : i18n.t("navigate_page.hide_steps");

  const handleOnClick = () => {
    setLabelState(!labelState);
    onClick();
  };

  return (
    <div onClick={handleOnClick} className={styles.separator}>
      <div className={styles.line}>&nbsp;</div>
      <span className={styles.text}>{option}</span>
    </div>
  );
};

export default RouteStepSeparator;
