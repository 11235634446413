import { IconType, Panorama } from "@livingmap/core-ui-v2";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { OperatingHours } from "../../redux/services/types";

import AssetDescription from "../AssetDescription/AssetDescription";
import FeatureImage from "../FeatureImage/FeatureImage";
import MetaData from "../MetaData/MetaData";
import OpenTimeInfo from "../OpenTimeInfo/OpenTimeInfo";
import DeparturesListCard from "../DeparturesListCard/DeparturesListCard";

import styles from "./FeatureInformationPanel.module.scss";
import {
  formatBusDepartures,
  formatMetroDepartures,
  formatRailDepartures,
} from "../../utils/formatDepartures";
import {
  TransportIdentifier,
  TransportInformation,
} from "../../hooks/useTransportService";
import TransportTimetable from "../TransportTimetable/TransportTimetable";
import RailAirDeparturesListCard from "../RailAirDeparturesListCard/RailAirDeparturesListCard";
import NoDepartures from "../NoDepartures/NoDepartures";
import { createPortal } from "react-dom";

interface FeatureMetaData {
  type: string;
  value: any;
  icon: IconType;
}

export interface Props {
  category: string;
  className?: string;
  dataQA: string;
  description: string;
  summary: string;
  distanceInTime?: string;
  imageUrl: string;
  panoramicImageUrl?: string;
  isTemporarilyClosed: boolean;
  metaData: FeatureMetaData[];
  name: string;
  onClose: () => void;
  onDirectionsClick: () => void;
  onDepartureClick?: (departure: TransportIdentifier) => void;
  operatingHours: OperatingHours;
  uid: number;
  transportInformation?: TransportInformation;
}

const FeatureInformationPanel: FC<Props> = ({
  description,
  imageUrl,
  panoramicImageUrl,
  isTemporarilyClosed,
  metaData,
  operatingHours,
  transportInformation,
  onDepartureClick,
}) => {
  const { t } = useTranslation();

  const [showPanorama, setShowPanorama] = useState(false);
  const now = new Date();
  const getMetaDataContent = (type: string, value: string) => {
    switch (type) {
      case "website":
        return (
          <a href={value} target="_blank" rel="noreferrer noopener">
            {value}
          </a>
        );
      case "phone":
        return (
          <a href={`tel:${value}`} rel="noreferrer noopener">
            {value}
          </a>
        );
      default:
        return <div>{value}</div>;
    }
  };

  const transportOrTimetable = transportInformation?.isTimetableData
    ? t("poi.timetabled_departures")
    : t("poi.live_departures");

  const formattedBusDepartures = formatBusDepartures(transportInformation, now);
  const formattedMetroDepartures = formatMetroDepartures(transportInformation);
  const formattedRailDepartures = formatRailDepartures(transportInformation);

  const departureListCards = formattedMetroDepartures.length ? (
    formattedMetroDepartures.map((metroService) => {
      return (
        <DeparturesListCard
          key={metroService.code}
          dataQA={`feature-departures-list-card-${metroService.code}`}
          transportIdentifier={{
            name: metroService.code,
            colour: metroService.colour,
          }}
          departuresByDestination={metroService.departures}
        />
      );
    })
  ) : (
    <NoDepartures title="No upcoming departures" />
  );

  return (
    <>
      {transportInformation?.transportMode === "bus" ? (
        <div className={styles.transportTimetable}>
          <span className={styles.transportTimetableTitle}>
            {transportOrTimetable}
          </span>
          <TransportTimetable
            dataQA="feature-transport-timetable"
            departures={formattedBusDepartures || []}
            onDepartureClick={onDepartureClick}
          />
        </div>
      ) : null}
      {transportInformation?.transportMode === "metro" ? (
        <div className={styles.transportTimetable}>
          <span className={styles.transportTimetableTitle}>
            {transportOrTimetable}
          </span>
          {departureListCards}
        </div>
      ) : null}
      {transportInformation?.transportMode === "rail" ? (
        <div className={styles.transportTimetable}>
          <span className={styles.transportTimetableTitle}>
            {transportOrTimetable}
          </span>
          <RailAirDeparturesListCard
            dataQA="feature-departures-list-card-rail"
            departures={formattedRailDepartures}
          />
        </div>
      ) : null}
      {imageUrl && (
        <FeatureImage
          dataQA="feature-image"
          alt="Feature location"
          url={imageUrl}
          className={styles.image}
          hasPanoramic={!!panoramicImageUrl}
          onPanoramicClick={() => setShowPanorama(true)}
        />
      )}
      {panoramicImageUrl &&
        showPanorama &&
        createPortal(
          <Panorama
            dataQA="feature-panoramic-image"
            imageUrl={panoramicImageUrl}
            onClose={() => setShowPanorama(false)}
            className={styles.panoramicImage}
            buildingBlockLabel={t("poi.panorama_building_block")}
          />,
          document.body,
        )}
      {description && (
        <div className={styles.description}>
          <AssetDescription
            dataQA="feature-description"
            content={description}
          />
        </div>
      )}
      {metaData.length || operatingHours ? (
        <div className={styles.metaData}>
          {operatingHours && (
            <OpenTimeInfo
              dataQA="feature-opening-times"
              isTemporarilyClosed={isTemporarilyClosed}
              operatingHours={operatingHours}
            />
          )}
          {metaData.map(({ type, icon, value }) => (
            <MetaData dataQA={`feature-${type}`} icon={icon} key={type}>
              {getMetaDataContent(type, value)}
            </MetaData>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default FeatureInformationPanel;
