import { LayerDelegate, LivingMapPlugin, Utils } from "@livingmap/core-mapping";
import { v4 as generateUUID } from "uuid";
import FloorControl from "./floor-control";
import { PLUGIN_IDS } from "./types";
import { GeoJSONSourceSpecification } from "mapbox-gl";

const {
  createGeoJSONFeature,
  createGeoJSONFeatureCollection,
  createGeoJSONGeometryPoint,
} = Utils;

const DEFAULT_SOURCE = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    features: [],
  },
} as GeoJSONSourceSpecification;

export default class LayerIconPlugin extends LivingMapPlugin {
  private ICON_SOURCE = "ICON_DISPLAY_SOURCE";
  private ICON_LAYER = "ICON_DISPLAY_LAYER";
  private layerDelegate: LayerDelegate | undefined;
  private mapInstance: mapboxgl.Map | undefined;
  private floorControl: FloorControl | undefined;

  public activate(): void {
    this.layerDelegate = this.LMMap.getLayerDelegate();
    this.mapInstance = this.LMMap.getMapboxMap();
    this.floorControl = this.LMMap.getPluginById<FloorControl>(
      PLUGIN_IDS.FLOOR,
    );
    this.createSource();
  }

  private createSource() {
    const iconDisplaySource = this.mapInstance!.getSource(this.ICON_SOURCE);
    if (!iconDisplaySource) {
      this.layerDelegate!.addSource(this.ICON_SOURCE, { ...DEFAULT_SOURCE });
    }
  }

  private updateSource(lon: number, lat: number, floorId: number) {
    const positionGeom = createGeoJSONFeature(
      {
        floor_id: floorId,
        id: generateUUID(),
      },
      createGeoJSONGeometryPoint([lon, lat]),
    );
    const geomCollection = createGeoJSONFeatureCollection([positionGeom]);
    this.layerDelegate!.getSourceProxy(this.ICON_SOURCE)?.setData(
      geomCollection,
    );
  }

  private updateLayer(iconName: string, offsetX: number, offsetY: number) {
    const iconDisplayLayer = this.mapInstance!.getLayer(this.ICON_LAYER);
    if (!iconDisplayLayer) {
      this.layerDelegate!.addLayer({
        id: this.ICON_LAYER,
        type: "symbol",
        source: this.ICON_SOURCE,
        layout: {
          "icon-image": iconName,
          "icon-offset": [offsetX, offsetY],
          "icon-pitch-alignment": "auto",
        },
        paint: {},
      } as mapboxgl.AnyLayer);
      this.floorControl?.refresh();
    } else {
      this.layerDelegate!.setLayoutProperty(
        this.ICON_LAYER,
        "icon-image",
        iconName,
      );
      this.layerDelegate!.setLayoutProperty(this.ICON_LAYER, "icon-offset", [
        offsetX,
        offsetY,
      ]);
    }
  }

  public addLayerIcon(
    icon: string,
    lon: number,
    lat: number,
    floor: number,
    offsetX: number,
    offsetY: number,
  ) {
    this.updateSource(lon, lat, floor);
    this.updateLayer(icon, offsetX, offsetY);
  }
}
