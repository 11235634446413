import { FC, KeyboardEvent, useContext } from "react";
import { Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";

import { RouteInputFocusContext } from "../../contexts/RouteInputFocusContext";

import styles from "./RouteInput.module.scss";
import { useTranslation } from "react-i18next";

export interface OriginDestinationLocation {
  type: "USER_DEFINED" | "SYSTEM_DEFINED";
  data?: string;
}

interface Props {
  dataQA: string;
  className?: string;
  origin?: OriginDestinationLocation;
  destination?: OriginDestinationLocation;
  onSwapButtonClick: () => void;
  onChooseOrigin: () => void;
  onChooseDestination: () => void;
  isDisabled?: boolean;
  onOriginChange?: (value: string) => void;
  onDestinationChange?: (value: string) => void;
  readonly?: boolean;
  onClearClick?: (selectDestination: boolean) => void;
  onSubmit?: (value: string, selectDestination: boolean) => void;
}

const RouteInput: FC<Props> = ({
  dataQA,
  className,
  origin,
  destination,
  onChooseDestination,
  onChooseOrigin,
  onSwapButtonClick,
  readonly = false,
  onOriginChange,
  onDestinationChange,
  isDisabled,
  onClearClick,
  onSubmit,
}) => {
  const focusContext = useContext(RouteInputFocusContext);
  const { t } = useTranslation();

  const handleSubmit = (
    e: KeyboardEvent<HTMLInputElement>,
    selectDestination: boolean,
  ) => {
    if (e.key === "Enter") {
      onSubmit && onSubmit(e.currentTarget.value, selectDestination);
    }
  };

  return (
    <div className={classNames(styles.container, className)} data-qa={dataQA}>
      <div className={styles.contentWrapper}>
        <div className={styles.inputWrapper}>
          <div className={styles.inputIconWrapper}>
            <Icon type="RouteStartIcon" dataQA="origin-icon" />
          </div>
          <input
            ref={focusContext?.originInputRef}
            readOnly={readonly}
            disabled={isDisabled}
            className={classNames(styles.input, {
              [styles.systemDefined]: origin?.type === "SYSTEM_DEFINED",
            })}
            placeholder={t("route_page.origin_placeholder")}
            value={origin && origin.data}
            onClick={onChooseOrigin}
            onChange={(e) => onOriginChange && onOriginChange(e.target.value)}
            onFocus={() => focusContext?.setOriginInputFocused(true)}
            onBlur={() =>
              setTimeout(() => {
                focusContext?.setOriginInputFocused(false);
              }, 300)
            }
            onKeyDown={(e) => handleSubmit(e, false)}
            data-qa="origin-input"
            aria-label="origin-input"
          />
          {focusContext?.originInputFocused && (
            <Icon
              type="ClearOutlinedIcon"
              dataQA="clear-origin-icon"
              className={classNames(styles.clearIcon, {
                [styles.hidden]:
                  !focusContext?.originInputRef.current?.value || readonly,
              })}
              onClick={() => onClearClick && onClearClick(false)}
            />
          )}
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.inputIconWrapper}>
            <Icon type="LocationIcon" dataQA="destination-icon" />
          </div>
          <input
            ref={focusContext?.destinationInputRef}
            readOnly={readonly}
            disabled={isDisabled}
            className={classNames(styles.input, {
              [styles.systemDefined]: destination?.type === "SYSTEM_DEFINED",
            })}
            placeholder={t("route_page.destination_placeholder")}
            value={destination && destination.data}
            onClick={onChooseDestination}
            onChange={(e) =>
              onDestinationChange && onDestinationChange(e.target.value)
            }
            onFocus={() => focusContext?.setDestinationInputFocused(true)}
            onBlur={() =>
              setTimeout(() => {
                focusContext?.setDestinationInputFocused(false);
              }, 300)
            }
            onKeyDown={(e) => handleSubmit(e, true)}
            data-qa="destination-input"
            aria-label="destination-input"
          />
          {focusContext?.destinationInputFocused && (
            <Icon
              type="ClearOutlinedIcon"
              dataQA="clear-destination-icon"
              className={classNames(styles.clearIcon, {
                [styles.hidden]:
                  !focusContext?.destinationInputRef.current?.value || readonly,
              })}
              onClick={() => onClearClick && onClearClick(true)}
            />
          )}
        </div>
      </div>
      <div
        className={styles.swapIconWrapper}
        onClick={() => {
          !isDisabled && onSwapButtonClick();
        }}
      >
        <Icon type="ImportExportIcon" dataQA="swap-origin-destination" />
      </div>
    </div>
  );
};

export default RouteInput;
