import { BottomSheetRef, Sheet } from "@livingmap/core-ui-v2";
import { useRef } from "react";
import { useLocation, Outlet } from "react-router-dom";

import { useRecentSearches } from "../../../../hooks/useRecentSearches";
import useResponsive from "../../../../hooks/useResponsive";
import { useSearchBarProps } from "../../../../hooks/useSearchBarProps";
import { useSearchSuggestions } from "../../../../hooks/useSearchSuggestions";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setTriggeredGeofenceId } from "../../../../redux/slices/applicationSlice";

import SearchTagsContainer from "../../../../containers/SearchTagsContainer/SearchTagsContainer";

import { HomeProps } from "../DesktopHomeView/DesktopHomeView";

import SearchBar from "../../../../components/SearchBar/SearchBar";

import styles from "./MobileHomeView.module.scss";

export default function MobileHomeView({ onClickSearchSuggestion }: HomeProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { Mobile } = useResponsive();

  const { queryParamsConfig, mapOptionsConfig } = useAppSelector(
    (state) => state.application,
  );

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const sheetRef = useRef<BottomSheetRef | null>(null);

  const { recentSearches } = useRecentSearches({
    className: styles.searchResult,
    activeClassName: styles.activeSelect,
    icon: "HistoryIcon",
  });

  const { setSearchSuggestion, searchSuggestionsList } = useSearchSuggestions({
    limit: 5,
    className: styles.searchResult,
    icon: "LocationOutlineIcon",
    handleOnClick: (value) => {
      setSearchSuggestion("");
      onClickSearchSuggestion(value, true);
    },
  });

  const searchBarProps = useSearchBarProps({
    ref: searchInputRef,
    className: styles.searchbar,
    onChange: (value: string) => {
      setSearchSuggestion(value.length > 1 ? value : "");
    },
    onSubmit: () => {
      setSearchSuggestion("");
    },
  });

  return (
    <>
      {Mobile && (
        <>
          {location.pathname === "/" &&
            queryParamsConfig["ui-search"] !== "hide" &&
            mapOptionsConfig.search &&
            queryParamsConfig["ui-controls"] !== "hide" && (
              <Sheet
                scrollLocking={false}
                dataQA="mobile-search-sheet"
                open
                ref={sheetRef}
                className={styles.mobileSearchSheet}
                defaultSnap={({ headerHeight }) => headerHeight}
                snapPoints={({ maxHeight, headerHeight }) => [
                  headerHeight,
                  maxHeight - 20,
                ]}
                onSpringStart={(event) => {
                  window.scrollTo(0, 0);

                  dispatch(setTriggeredGeofenceId(null));

                  if (event.type === "SNAP" && event.source === "dragging") {
                    searchInputRef.current?.blur(); // accessed from the child component
                  }
                }}
                onSpringEnd={() => {
                  window.scrollTo(0, 0);
                }}
                skipInitialTransition
                header={
                  <>
                    <SearchBar
                      {...searchBarProps}
                      dataQA="mobile-search"
                      flatInput
                      align="center"
                      cancelButton
                      compact
                      roundedClearIcon
                      onClose={() => {
                        sheetRef.current?.snapTo(
                          ({ snapPoints }) => snapPoints[0], // Snap back to closed state on cancel search
                        );
                        setSearchSuggestion("");
                      }}
                      onFocus={() => {
                        setSearchSuggestion("");
                        setTimeout(() => {
                          sheetRef.current?.snapTo(
                            ({ maxHeight }) => maxHeight,
                          );
                        }, 100);
                      }}
                    />
                    <SearchTagsContainer onClose={searchBarProps.onClose} />
                  </>
                }
              >
                {recentSearches.length > 0 &&
                  searchSuggestionsList.length === 0 &&
                  recentSearches}
                {searchSuggestionsList.length > 0 && searchSuggestionsList}
                {recentSearches.length === 0 &&
                  searchSuggestionsList.length === 0 && (
                    <div style={{ padding: 10 }} />
                  )}
              </Sheet>
            )}
          <Outlet />
        </>
      )}
    </>
  );
}
