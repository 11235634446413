import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import classNames from "classnames";
import LivingMap, {
  LMFeature,
  LivingMapOptions,
} from "@livingmap/core-mapping";
import {
  FloorSelector,
  FloatingIconButton,
  Icon,
  Spinner,
  Tooltip,
  ZoomControl,
  Sheet,
} from "@livingmap/core-ui-v2";

import {
  Button,
  Dialog,
  FloatingIconButton as HippoFloatingIconButton,
  IconButton,
  TextCard,
} from "@livingmap/hippo";

import {
  Geofence,
  LanguageConfig,
  MappedBookmark,
  MappedFloor,
  Region,
} from "../../redux/services/types";
import useLocationPermission from "../../hooks/useLocationPermission";
import useMotionAndOrientationPermission from "../../hooks/useMotionAndOrientationPermission";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setLanguage,
  setTriggeredGeofenceId,
  storeLocation,
} from "../../redux/slices/applicationSlice";
import useResponsive from "../../hooks/useResponsive";
import { usePostAnalyticsEvent } from "../../hooks/usePostAnalyticsEvent";

import FloorControl from "./plugins/floor-control";
import ClusteredPinPlugin from "./plugins/clustered-pin-control";
import InteractionPlugin from "./plugins/interaction-control";
import { PLUGIN_IDS } from "./plugins/types/index";
import PositionPlugin from "./plugins/position-control";
import LayerIconPlugin from "./plugins/layer-icon-control";
import RoutingPlugin from "./plugins/routing-control";
import RegionPlugin from "./plugins/regions-control";
import GeofencePlugin from "./plugins/geofence-control";

import { buildRoutingPath } from "../../utils/buildRoutingPath";
import { push, replace } from "../../utils/navigate";
import { Path, RoutingPath } from "../../utils/types";
import { AnalyticsEvent } from "../../utils/analyticsTypes";
import { parseLanguageObject } from "../../utils/parseLanguageObject";

import CompassButton from "../CompassButton/CompassButton";
import LocationButton, {
  LocationStatus,
} from "../LocationButton/LocationButton";

import { setLocationStatus } from "../../redux/slices/applicationSlice";

import "mapbox-gl/dist/mapbox-gl.css";
import styles from "./Map.module.scss";
import DebugControl from "./plugins/debug-control";
import { useTranslation } from "react-i18next";
import i18n from "../../i18next";
import Advert from "../Advert/Advert";

export interface MapCentre {
  lat: number;
  lng: number;
}

interface Props {
  dataQA: string;
  mapID: string;
  zoom: number;
  maxZoom: number;
  minZoom: number;
  center: [number, number];
  extent: [number, number, number, number];
  bearing: number;
  mapStyle: string;
  accessToken: string;
  floor: MappedFloor;
  floors: MappedFloor[];
  regions: Region[];
  bookmarks: MappedBookmark[];
  attributionHTML: string;
  onFeatureSelect?: (feature: LMFeature) => void;
  onMapReady?: (map: LivingMap) => void;
  onFloorChange?: (floor: MappedFloor) => void;
  enableUI?: boolean;
  enableUserLocation?: boolean;
  mapInstanceRef: React.MutableRefObject<LivingMap | null>;
  positionOnRoute: boolean;
  availableLanguages: LanguageConfig[];
  geofences?: Geofence[];
  searchTags?: boolean;
}

const Map: React.FC<Props> = ({
  dataQA,
  mapID,
  bearing,
  center,
  extent,
  maxZoom,
  minZoom,
  zoom,
  mapStyle,
  accessToken,
  floor,
  floors,
  regions,
  bookmarks,
  attributionHTML,
  onFeatureSelect,
  onMapReady,
  enableUI = true,
  enableUserLocation = true,
  onFloorChange,
  mapInstanceRef,
  positionOnRoute,
  availableLanguages,
  geofences,
  searchTags,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  // Map state
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationErrorDialogOpen, setIsLocationErrorDialogOpen] =
    useState(false);
  const [isMapAttributionOpen, setIsMapAttributionOpen] = useState(false);
  const [liveBearing, setLiveBearing] = useState(0);
  const [liveZoom, setLiveZoom] = useState(0);
  const [liveFloors, setLiveFloors] = useState<MappedFloor[]>([]);
  const [locationRequestSuccessful, setLocationRequestSuccessful] =
    useState(false);

  const isOnJourneyScreen = useMatch(Path.JOURNEY);
  const isOnHomeScreen = useMatch(Path.HOME);

  // Get location status and coords from redux
  const { locationStatus, queryParamsConfig, language, triggeredGeofenceId } =
    useAppSelector((state) => state.application);

  const triggeredGeofence = useMemo(
    () => geofences?.find((geofence) => geofence.uuid === triggeredGeofenceId),
    [geofences, triggeredGeofenceId],
  );

  const { logAnalyticsEvent } = usePostAnalyticsEvent();

  const handleLocationButtonClick = () => {
    const position = positionControlInstance.current?.getCurrentPosition();
    if (locationStatus === LocationStatus.FOUND && position)
      mapInstanceRef.current?.getMapboxMap().easeTo({
        center: [position.longitude, position.latitude],
      });
    else handleLocationRequest();

    if (locationStatus === LocationStatus.INACTIVE)
      positionControlInstance.current?.resetStabilisation();

    requestMotionAndOrientationPermission();
  };

  // Location permission hook
  const { handleLocationRequest } = useLocationPermission({
    setLocationMarker: ({
      latitude,
      longitude,
      bearing,
      altitude,
      accuracy,
    }) => {
      positionControlInstance.current?.setMarker({
        latitude,
        longitude,
        floorID: floor.id,
        floor: floor.floor,
        bearing,
        altitude,
        accuracy,
        ts: new Date(),
      });
    },
    handleUserWithinGeofence: ({ latitude, longitude }) => {
      if (isOnHomeScreen)
        geofenceControlInstance.current?.handleUserLocationForGeofences({
          latitude,
          longitude,
        });
    },
    mockGeolocationCallback: handleLocationButtonClick,
  });

  const { requestMotionAndOrientationPermission } =
    useMotionAndOrientationPermission();

  const { Mobile } = useResponsive();

  // Container for the map
  const mapContainer = useRef<HTMLDivElement | null>(null);

  // Map Plugins
  const clusteredPinControlInstance = useRef<ClusteredPinPlugin | null>(null);
  const floorControlInstance = useRef<FloorControl | null>(null);
  const interactionControlInstance = useRef<InteractionPlugin | null>(null);
  const positionControlInstance = useRef<PositionPlugin | null>(null);
  const layerIconControlInstance = useRef<LayerIconPlugin | null>(null);
  const routingControlInstance = useRef<RoutingPlugin | null>(null);
  const regionControlInstance = useRef<RegionPlugin | null>(null);
  const debugControlInstance = useRef<DebugControl | null>(null);
  const geofenceControlInstance = useRef<GeofencePlugin | null>(null);

  //i18next translation hook
  const { t } = useTranslation();

  // Language select sheet state
  const [languageSelectSheetOpen, setLanguageSelectSheetOpen] = useState(false);

  // Ease the map to the user location when we have locationRequestSuccessful is currently false
  useEffect(() => {
    const position = positionControlInstance.current?.getCurrentPosition();
    if (!locationRequestSuccessful && position) {
      setLocationRequestSuccessful(true);
      mapInstanceRef.current?.getMapboxMap().easeTo({
        center: [position.longitude, position.latitude],
      });
    }
  }, [locationStatus, locationRequestSuccessful, mapInstanceRef]);

  useEffect(() => {
    if (locationStatus === LocationStatus.FOUND)
      logAnalyticsEvent({ event_type: AnalyticsEvent.SHOW_POSITION_ON });
  }, [locationStatus, logAnalyticsEvent]);

  // Map initialisation
  useEffect(() => {
    setIsLoading(true);

    const mapConfig: LivingMapOptions = {
      accessToken,
      zoom,
      maxZoom,
      minZoom,
      center,
      extent,
      bearing,
      style: mapStyle,
      hash: true,
      enablePitchWithRotate: true,
      enableTouchPitch: true,
    };

    const map = new LivingMap(mapContainer.current!, mapConfig);

    floorControlInstance.current = new FloorControl(PLUGIN_IDS.FLOOR, map);

    clusteredPinControlInstance.current = new ClusteredPinPlugin(
      PLUGIN_IDS.CLUSTERED_PIN,
      map,
      floorControlInstance.current,
    );

    interactionControlInstance.current = new InteractionPlugin(
      PLUGIN_IDS.INTERACTION,
      map,
    );

    routingControlInstance.current = new RoutingPlugin(
      PLUGIN_IDS.ROUTING,
      map,
      floors,
      onFloorChange,
    );

    positionControlInstance.current = new PositionPlugin(
      PLUGIN_IDS.USER_LOCATION,
      map,
      positionOnRoute,
      routingControlInstance.current,
      queryParamsConfig.debug === "enable",
      // onStabilisationSuccess
      () => {
        dispatch(setLocationStatus(LocationStatus.FOUND));
      },
      // onStabilisationFailure
      () => {
        setIsLocationErrorDialogOpen(true);
        dispatch(setLocationStatus(LocationStatus.INACTIVE));
      },
    );

    layerIconControlInstance.current = new LayerIconPlugin(
      PLUGIN_IDS.LAYER_ICON,
      map,
    );

    regionControlInstance.current = new RegionPlugin(
      PLUGIN_IDS.REGION,
      map,
      regions,
      floors,
    );

    debugControlInstance.current = new DebugControl(PLUGIN_IDS.DEBUG, map);

    geofenceControlInstance.current = new GeofencePlugin(
      PLUGIN_IDS.GEOFENCE,
      map,
      floorControlInstance.current,
      (geofenceId) => dispatch(setTriggeredGeofenceId(geofenceId)),
    );

    map.addPlugin(floorControlInstance.current);
    map.addPlugin(clusteredPinControlInstance.current);
    map.addPlugin(interactionControlInstance.current);
    map.addPlugin(positionControlInstance.current);
    map.addPlugin(layerIconControlInstance.current);
    map.addPlugin(routingControlInstance.current);
    map.addPlugin(regionControlInstance.current);
    map.addPlugin(debugControlInstance.current);
    map.addPlugin(geofenceControlInstance.current);

    map.create();

    map.on("style.load", () => {
      floorControlInstance.current?.setGroundFloor(floors);
      floorControlInstance.current?.setAllFloors(floors);
      floorControlInstance.current?.setActiveFloor(floor);

      if (geofences) {
        geofenceControlInstance.current?.setGeofences(geofences);
      }

      if (onFeatureSelect) {
        interactionControlInstance.current?.subscribeToFeatureSelect(
          onFeatureSelect,
        );
      }

      // Initialise map instance and state values
      mapInstanceRef.current = map;

      setLiveZoom(zoom);
      map.getMapboxMap().setMaxPitch(60);
      if (map.getMapboxMap().getBearing() === 0) {
        map.getMapboxMap().setBearing(bearing);
        setLiveBearing(bearing);
      } else {
        setLiveBearing(map.getMapboxMap().getBearing());
      }

      if (!regionControlInstance.current) {
        setLiveFloors(floors);
      } else {
        setLiveFloors(regionControlInstance.current.getAvailableFloors());
      }
    });

    map.on("render", function () {
      map?.getMapboxMap().resize();
    });

    map.on("load", () => {
      // Add the instance of LivingMap to the window object to assist with testing
      if (process.env.REACT_APP_STAGING === "true") {
        window.livingMap = map;
        console.log(
          "LivingMap instance injected into window.livingMap successfully",
        );
      }

      if (queryParamsConfig["ui-gestures"] === "disable") {
        map.getMapboxMap().scrollZoom.disable();
        map.getMapboxMap().dragPan.disable();
        map.getMapboxMap().doubleClickZoom.disable();
        map.getMapboxMap().touchZoomRotate.disable();
      }

      if (queryParamsConfig.consoleLocation === "enable") {
        window.api = {
          ...window.api,
          getCenter: () => map.getMapboxMap().getCenter(),
        };
      }

      setIsLoading(false);
      onMapReady && onMapReady(map);
      handleRegionUpdate();

      logAnalyticsEvent({
        event_type: AnalyticsEvent.MAP_LOAD,
        event_data: {
          type: window !== window.parent ? "embedded" : "native",
          ...(queryParamsConfig.source && { source: queryParamsConfig.source }),
        },
      });
    });

    map.getMapboxMap().on("rotate", () => {
      setLiveBearing(map.getMapboxMap().getBearing());
    });

    map.getMapboxMap().on("zoom", () => {
      setLiveZoom(Math.floor(map.getMapboxMap().getZoom()));
    });

    map.getMapboxMap().on("resize", () => {
      // timeout to fix flickering issue
      setTimeout(() => map.getMapboxMap().resize(), 50);
    });

    map.getMapboxMap().on("zoomend", handleRegionUpdate);
    map.getMapboxMap().on("dragend", handleRegionUpdate);
    map.getMapboxMap().on("moveend", handleRegionUpdate);

    // eslint-disable-next-line
  }, [mapID, language]); // these are the only required dependencies

  const handleRegionUpdate = () => {
    if (regionControlInstance.current) {
      regionControlInstance.current.updateAvailableFloors();
      setLiveFloors(regionControlInstance.current.getAvailableFloors());
    }
  };

  const handleMapZoomIn = () => {
    mapInstanceRef?.current?.zoomOutOneLevel();
  };

  const handleMapZoomOut = () => {
    mapInstanceRef?.current?.zoomInOneLevel();
  };

  const handleFloorSelect = (newFloor: MappedFloor) => {
    floorControlInstance.current?.setActiveFloor(newFloor);
    onFloorChange && onFloorChange(newFloor);

    logAnalyticsEvent({
      event_type: AnalyticsEvent.MAP_LEVEL_CHANGE,
      event_data: {
        floor_from: floor.floor,
        floor_to: newFloor.floor,
      },
    });
  };

  const handleBookmarkSelect = (bookmark: MappedBookmark) => {
    mapInstanceRef.current?.getMapboxMap().easeTo({
      center: [bookmark.center.longitude, bookmark.center.latitude],
      bearing: bookmark.bearing,
      zoom: bookmark.zoom,
    });

    floorControlInstance.current?.setActiveFloor(bookmark.floor);

    logAnalyticsEvent({
      event_type: AnalyticsEvent.BOOKMARK_SELECT,
      event_data: bookmark.name,
    });
  };

  useEffect(() => {
    if (!isOnHomeScreen) dispatch(setTriggeredGeofenceId(null));
  }, [dispatch, isOnHomeScreen]);

  return (
    <div data-qa={dataQA} className={styles.container}>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <Spinner dataQA="map-loading-spinner" type="BeatLoader" />
        </div>
      )}
      <div ref={mapContainer} className={styles.map} />
      {enableUI && floors.length > 1 && (
        <div className={styles.floorSelectorContainer}>
          <Tooltip
            dataQA="floor-selector-tooltip"
            className={styles.tooltip}
            triggerComponent={
              <FloorSelector
                currentUserFloor={null}
                dataQA="map-floor-selector"
                currentFloor={Object.keys(liveFloors).length ? floor : null}
                onFloorSelect={handleFloorSelect}
                onClick={() =>
                  logAnalyticsEvent({
                    event_type: AnalyticsEvent.LEVEL_SELECTOR_TOUCH,
                  })
                }
                options={Object.values(liveFloors)}
                bookmarks={bookmarks}
                onBookmarkSelect={handleBookmarkSelect}
                placement={Mobile ? "bottom" : "bottom-end"}
                findABuildingPlaceholder={t(
                  "home.floor_selector_find_building",
                )}
              />
            }
            children={<p>{t("home.floor_selector_tooltip")}</p>}
          />
        </div>
      )}
      {enableUI && availableLanguages.length > 1 && (
        <div className={styles.leftMobileControlsContainer}>
          <HippoFloatingIconButton
            dataQA="mobile-language-switcher"
            icon="language"
            size="regular"
            iconSize={24}
            type="primary"
            onClick={() => setLanguageSelectSheetOpen(true)}
          />
        </div>
      )}
      {enableUI && (
        <div className={styles.mapControlsContainer}>
          <div className={styles.control}>
            <Tooltip
              dataQA="compass-tooltip"
              className={styles.tooltip}
              triggerComponent={
                <CompassButton
                  size={Mobile ? "medium" : "small"}
                  bearing={liveBearing}
                  dataQA="map-compass"
                  onClick={() => {
                    if (
                      mapInstanceRef?.current?.getMapboxMap().getBearing() === 0
                    ) {
                      mapInstanceRef?.current
                        ?.getMapboxMap()
                        .easeTo({ bearing });
                      logAnalyticsEvent({
                        event_type: AnalyticsEvent.NORTH_ORIENTATION_OFF,
                      });
                    } else {
                      mapInstanceRef?.current
                        ?.getMapboxMap()
                        .easeTo({ bearing: 0 });
                      logAnalyticsEvent({
                        event_type: AnalyticsEvent.NORTH_ORIENTATION_ON,
                      });
                    }
                  }}
                />
              }
              children={<p>{t("home.compass_tooltip")}</p>}
            />
          </div>
          <div
            className={classNames({
              [styles.bottom]: isOnJourneyScreen,
            })}
          >
            {isOnJourneyScreen && (
              <div
                className={classNames(
                  styles.control,
                  styles.journeyOverviewControl,
                )}
              >
                <FloatingIconButton
                  dataQA={"journey-overview-button"}
                  icon={"JourneyOverviewIcon"}
                  onClick={() => {
                    dispatch(storeLocation(location));

                    const { fromName, fromId, toName, toId } =
                      isOnJourneyScreen.params;

                    dispatch(
                      push({
                        pathOrLocation: buildRoutingPath(
                          RoutingPath.JOURNEY_OVERVIEW,
                          {
                            fromId,
                            fromName,
                            toId,
                            toName,
                          },
                        ),
                      }),
                    );
                  }}
                  rounded
                  size={Mobile ? "medium" : "small"}
                />
              </div>
            )}
            {enableUserLocation && (
              <div className={classNames(styles.control)}>
                <Tooltip
                  dataQA="user-location-tooltip"
                  className={styles.tooltip}
                  triggerComponent={
                    <LocationButton
                      dataQA="map-user-location"
                      onClick={handleLocationButtonClick}
                      status={locationStatus}
                      size={Mobile ? "medium" : "small"}
                    />
                  }
                  children={<p>{t("home.locate_me_tooltip")}</p>}
                />
              </div>
            )}
          </div>
          <div className={styles.zoomControl}>
            <Tooltip
              dataQA="zoom-tooltip"
              className={styles.tooltip}
              triggerComponent={
                <ZoomControl
                  dataQA="map-zoom-control"
                  maxZoomReached={liveZoom >= maxZoom}
                  minZoomReached={liveZoom <= minZoom}
                  onZoomInClick={() => {
                    setLiveZoom(
                      Math.floor(
                        mapInstanceRef?.current?.getMapboxMap().getZoom()!,
                      ),
                    );
                    handleMapZoomIn();
                  }}
                  onZoomOutClick={() => {
                    setLiveZoom(
                      Math.floor(
                        mapInstanceRef?.current?.getMapboxMap().getZoom()!,
                      ),
                    );
                    handleMapZoomOut();
                  }}
                />
              }
              children={<p>{t("home.zoom_tooltip")}</p>}
            />
          </div>
        </div>
      )}
      <Icon
        dataQA="map-attribution"
        type="InfoOutlinedIcon"
        onClick={() => {
          setIsMapAttributionOpen(true);
          logAnalyticsEvent({ event_type: AnalyticsEvent.ATTRIBUTION_OPEN });
        }}
        className={styles.attribution}
      />
      {Mobile && (
        <Sheet
          dataQA="language-select-sheet"
          open={languageSelectSheetOpen}
          className={styles.languageSelectSheet}
          header={
            <div className={styles.languageSelectSheetHeader}>
              <span>Language</span>
              <IconButton
                dataQA="language-select-sheet-close-button"
                icon="close"
                iconSize={24}
                size="regular"
                type="primary"
                onClick={() => setLanguageSelectSheetOpen(false)}
              />
            </div>
          }
        >
          <ul>
            {availableLanguages.map((availableLanguage) => (
              <li key={availableLanguage.id}>
                <TextCard
                  dataQA="language-select-option"
                  title={availableLanguage.human_readable_language}
                  subtitle={availableLanguage.human_readable_region}
                  onClick={() => {
                    dispatch(setLanguage(availableLanguage.id));
                    i18n.changeLanguage(availableLanguage.id);

                    dispatch(
                      replace({
                        pathOrLocation: location.pathname,
                        newQueryParams: {
                          lang: availableLanguage.id,
                        },
                      }),
                    );
                  }}
                  active={availableLanguage.id === language}
                />
              </li>
            ))}
          </ul>
        </Sheet>
      )}
      <Dialog
        dataQA="location-error-dialog"
        isOpen={isLocationErrorDialogOpen}
        title="Location not found"
        width={320}
        footer={
          <Button
            dataQA="location-error-dialog-ok-button"
            text="OK"
            size="regular"
            type="primary"
            style="filled"
            onClick={() => setIsLocationErrorDialogOpen(false)}
          />
        }
      >
        Your location could not be determined at this time. Please try again.
      </Dialog>
      <Dialog
        dataQA="map-attribution-dialog"
        isOpen={isMapAttributionOpen}
        closable={false}
        contextMenu={false}
        width={300}
        footer={
          <Button
            dataQA="map-attribution-dialog-ok-button"
            text="OK"
            size="regular"
            type="primary"
            style="filled"
            onClick={() => setIsMapAttributionOpen(false)}
          />
        }
      >
        <div
          className={styles.attributionDialog}
          dangerouslySetInnerHTML={{ __html: attributionHTML }}
        />
      </Dialog>
      {enableUI && isOnHomeScreen && Mobile && triggeredGeofence && (
        <Advert
          dataQA="geofence-advert"
          className={classNames(styles.advert, {
            [styles.extendedPadding]: searchTags,
          })}
          imageUrl={triggeredGeofence.image_url || ""}
          newTab={triggeredGeofence.new_tab}
          adUrl={triggeredGeofence.ad_url}
          onClose={() => dispatch(setTriggeredGeofenceId(null))}
          title={parseLanguageObject(triggeredGeofence.title, language) || ""}
          {...(triggeredGeofence.subtitle
            ? {
                subtitle:
                  parseLanguageObject(triggeredGeofence.subtitle, language) ||
                  "",
              }
            : {})}
          {...(triggeredGeofence.text
            ? {
                text:
                  parseLanguageObject(triggeredGeofence.text, language) || "",
              }
            : {})}
        />
      )}
    </div>
  );
};

export default Map;
