import { Button, Icon, IconType } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import styles from "./SearchBar.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  placeholder: string;
  className?: string;
  dataQA: string;
  onChange: (value: string) => void;
  onClose: () => void;
  cancelButton?: boolean;
  align?: "left" | "center";
  roundedClearIcon?: boolean;
  flatInput?: boolean;
  onSubmit: (value: string) => void;
  onFocus?: () => void;
  onKeyDown?: (key: string) => boolean;
  value?: string;
  compact?: boolean;
  charLimit?: number;
  icon?: IconType;
  iconStyle?: Object;
  autoFocus?: boolean;
  persistValueOnBlur?: boolean;
}

const SearchBar = forwardRef(function SearchBar(
  {
    placeholder = "Search",
    dataQA,
    onChange,
    className,
    onClose,
    cancelButton,
    align = "left",
    roundedClearIcon,
    flatInput,
    onSubmit,
    onFocus,
    onKeyDown,
    value = "",
    compact,
    charLimit = 100,
    icon = "SearchIcon",
    iconStyle = { top: "3px" },
    autoFocus,
    persistValueOnBlur = true,
  }: Props,
  forwardedRef,
) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  const clearSearch = () => {
    inputRef.current?.blur();
    setInputValue("");
    onClose();
  };

  const customInstance = {
    blur: () => {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    },
    select: () => {
      if (inputRef.current) {
        inputRef.current.select();
      }
    },
  };

  useImperativeHandle(forwardedRef, () => customInstance);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const submitInput = (inputValue: string): void => {
    onSubmit(inputValue);
    inputRef.current?.blur();
  };

  return (
    <div className={classNames(className, styles.container)}>
      <div
        className={classNames(className, styles.inputContainer, {
          [styles.flatInput]: flatInput,
          [styles.dirty]: inputValue.length && flatInput,
        })}
        data-qa={dataQA}
      >
        <input
          id={dataQA + "-search"}
          aria-labelledby={dataQA + "-search-label"}
          autoFocus={autoFocus}
          ref={inputRef}
          value={inputValue}
          className={classNames(styles.input, {
            [styles.compact]: compact,
            [styles.flatInput]: flatInput,
          })}
          maxLength={charLimit}
          placeholder={placeholder}
          enterKeyHint="search"
          onChange={(e) => {
            e.preventDefault();
            const { value } = e.target;
            setInputValue(value);
            onChange(value);
          }}
          onFocus={() => {
            onFocus && onFocus();
          }}
          onBlur={() => persistValueOnBlur && setInputValue(value)}
          onKeyDown={(event) => {
            if (onKeyDown && onKeyDown(event.key)) {
              event.preventDefault();
              return;
            }
            if (event.key === "Enter" && inputValue.trim()) {
              submitInput(inputValue);
            }
          }}
        />
        <label
          id={dataQA + "-search-label"}
          htmlFor={dataQA + "-search"}
          className={classNames(styles.placeholder, {
            [styles.alignCenter]: align === "center",
            [styles.compact]: compact,
          })}
        >
          <div className={styles.iconContainer}>
            <Icon type={icon} style={iconStyle} dataQA="search-icon" />
          </div>
          <span
            className={classNames(styles.placeholderText, {
              [styles.compact]: compact,
            })}
          >
            {placeholder}
          </span>
        </label>

        {inputValue.length ? (
          <div
            className={classNames(styles.clearIcon, {
              [styles.noBorderClearIcon]: !roundedClearIcon,
              [styles.compact]: compact,
            })}
            onPointerDown={(e) => {
              e.preventDefault();
              if (roundedClearIcon) {
                setInputValue("");
                onChange("");
              } else clearSearch();
            }}
          >
            <Icon
              type={roundedClearIcon ? "ClearOutlinedIcon" : "CloseIcon"}
              dataQA="clear-value-icon"
            />
          </div>
        ) : null}
        {inputValue.length ? (
          <Icon
            type="CloseIcon"
            dataQA="close-icon"
            className={classNames(styles.closeIcon, {
              [styles.compact]: compact,
            })}
            onClick={clearSearch}
          />
        ) : null}
      </div>
      {cancelButton ? (
        <Button
          className={styles.cancelButton}
          onClick={clearSearch}
          dataQA="cancel-button"
          color="white"
          rounded
        >
          {t("search_bar.cancel_button_text")}
        </Button>
      ) : null}
    </div>
  );
});

export default SearchBar;
