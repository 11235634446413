import { FC, useContext, useEffect, useState } from "react";
import {
  FeatureQueryResponse,
  RouteMilestoneFeature,
} from "../../../../redux/services/types";
import Panel from "../../../../components/Panel/Panel";
import useResponsive from "../../../../hooks/useResponsive";
import RouteInput from "../../../../components/RouteInput/RouteInput";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Button, Icon } from "@livingmap/core-ui-v2";

import styles from "./DesktopRoutingView.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { push } from "../../../../utils/navigate";
import {
  clearLocation,
  storeLocation,
} from "../../../../redux/slices/applicationSlice";
import { buildRoutingPath } from "../../../../utils/buildRoutingPath";
import RoutingQuickActions from "../../components/RoutingQuickActions/RoutingQuickActions";
import ListItemResult, {
  COLOURS,
} from "../../../../components/ListItemResult/ListItemResult";
import { RouteInputFocusContext } from "../../../../contexts/RouteInputFocusContext";
import classNames from "classnames";
import {
  QueryParamBinary,
  QueryParams,
  RoutingPath,
} from "../../../../utils/types";
import SearchResults from "../../components/SearchResults/SearchResults";
import LoadingChip from "../../../SearchResultsView/components/LoadingChip";
import CollapsablePanel from "../../../../components/CollapsablePanel/CollapsablePanel";
import RouteContent from "../../../RouteView/components/RouteContent/RouteContent";
import ShareDialog from "../../../../components/ShareDialog/ShareDialog";
import { useGetMapConfigQuery } from "../../../../redux/services/vectormapsAPI";
import { useSearchSuggestions } from "../../../../hooks/useSearchSuggestions";
import { truncateString } from "../../../../utils/truncateString";
import useGetRouteInputData from "../../../../hooks/useGetRouteInputData";
import { ENVIRONMENT } from "../../../../constants";
import { useRouteMatch } from "../../../../hooks/useRouteMatch";
import { Trans, useTranslation } from "react-i18next";
import { showRouteTime } from "../../../../utils/showRouteTime";

interface Props {
  query?: string;
  onSearchResultClick?: (id: number, name: string) => void;
  searchResults?: FeatureQueryResponse[];
  isFetchingSearchResults?: boolean;
  isRouteSuccess?: boolean;
  chipContainerRef?: HTMLDivElement | null;
  isFetchingRoute?: boolean;
  routeMilestones?: RouteMilestoneFeature[];
  totalTime?: number;
  timeToDestinationComponent?: JSX.Element | null;
  stepFreeToggleComponent?: JSX.Element | null;
  mapLoaded: boolean | null;
}

const DesktopRoutingView: FC<Props> = ({
  query,
  isFetchingSearchResults,
  isRouteSuccess,
  onSearchResultClick,
  searchResults,
  chipContainerRef,
  isFetchingRoute,
  routeMilestones,
  timeToDestinationComponent,
  stepFreeToggleComponent,
  totalTime,
  mapLoaded,
}) => {
  const { Default } = useResponsive();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname, state } = location;
  const { fromName, fromId, toName, toId } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const stepFreeRoute =
    searchParams.get(QueryParams.STEP_FREE) === QueryParamBinary.ENABLED;

  let blurTimeout: string | number | NodeJS.Timeout | undefined;

  const { data: mapData } = useGetMapConfigQuery();

  const { queryParamsConfig, language, project, routingUserActiveFloor } =
    useAppSelector((state) => state.application);

  const isUsingFromCurrentLocation = fromId?.includes(",");
  const isUsingToCurrentLocation = toId?.includes(",");

  const { isOnRouteScreen, isOnRoutingScreen } = useRouteMatch();

  const [origin, setOrigin] = useState(fromName === "-" ? "" : fromName);
  const [destination, setDestination] = useState(toName === "-" ? "" : toName);
  const [buttonText, setButtonText] = useState("");
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [inputCleared, setInputCleared] = useState(false);

  // get routing input focus context
  const focusContext = useContext(RouteInputFocusContext);
  if (!focusContext) throw new Error("No focus context found");
  const {
    originInputFocused,
    destinationInputFocused,
    originInputRef,
    destinationInputRef,
    setDestinationInputFocused,
    setOriginInputFocused,
  } = focusContext;

  const { searchSuggestionsList, setSearchSuggestion } = useSearchSuggestions({
    limit: 5,
    className: styles.searchResult,
    handleOnClick: (value) => {
      if (originInputFocused && origin) {
        handleOnSearchSubmit(value, false, true);
      } else if (destinationInputFocused && destination) {
        handleOnSearchSubmit(value, true, true);
      }
    },
  });

  const { getOriginRouteData, getDesintationRouteData } = useGetRouteInputData({
    origin,
    destination,
  });

  const handleChoosePoi = (selectDestination: boolean) => {
    dispatch(storeLocation(location));

    dispatch(
      push({
        pathOrLocation: buildRoutingPath(RoutingPath.ROUTE, {
          fromName,
          fromId,
          toName,
          toId,
        }),
        state: { selectDestination },
      }),
    );
  };

  const handleCloseButtonClick = () => {
    dispatch(clearLocation());
    dispatch(
      push({
        pathOrLocation: "/",
        discardParams: [QueryParams.STEP_FREE, QueryParams.QUERY],
      }),
    );
  };

  const handleClearInputValue = (selectDestination: boolean) => {
    dispatch(storeLocation(location));

    selectDestination ? handleDestinationChange("") : handleOriginChange("");

    dispatch(
      push({
        pathOrLocation: selectDestination
          ? buildRoutingPath(RoutingPath.ROUTE, { fromName, fromId })
          : buildRoutingPath(RoutingPath.ROUTE, { toName, toId }),
        state: { selectDestination },
      }),
    );

    setInputCleared(true);
  };

  const handleOriginChange = (value: string) => {
    setOrigin(value);
    setButtonText(`${value}`);
    setShowSearchResults(false);

    setSearchSuggestion(value.length > 1 ? value : "");
  };

  const handleDestinationChange = (value: string) => {
    setDestination(value);
    setButtonText(`${value}`);
    setShowSearchResults(false);

    setSearchSuggestion(value.length > 1 ? value : "");
  };

  const handleOnSearchSubmit = (
    value: string,
    selectDestination: boolean,
    nameSearch: boolean = false,
  ) => {
    // if the user does a search, we just go to the same route with the search query param
    // if the value of the search is empty, remove it from the url params
    if (value === query) {
      setShowSearchResults(true);
      return;
    }

    if (nameSearch) {
      dispatch(
        push({
          pathOrLocation: pathname,
          ...(value.length === 0 && {
            discardParams: [QueryParams.QUERY, QueryParams.NAME_SEARCH],
          }),
          ...(value.length > 0 && {
            newQueryParams: {
              [QueryParams.QUERY]: value,
              [QueryParams.NAME_SEARCH]: QueryParamBinary.ENABLED,
            },
          }),
          state: { selectDestination },
        }),
      );
    } else {
      dispatch(
        push({
          pathOrLocation: pathname,
          ...(value.length === 0 && {
            discardParams: [QueryParams.QUERY, QueryParams.NAME_SEARCH],
          }),
          ...(value.length > 0 && {
            newQueryParams: { [QueryParams.QUERY]: value },
            discardParams: [QueryParams.NAME_SEARCH],
          }),
          state: { selectDestination },
        }),
      );
    }
  };

  const handleSwapButtonClick = () => {
    dispatch(
      push({
        pathOrLocation: buildRoutingPath(RoutingPath.NAVIGATE, {
          fromName: toName,
          fromId: toId,
          toName: fromName,
          toId: fromId,
        }),
      }),
    );
  };

  // generate the route share url based on the environment
  const shareUrl = () => {
    const stepFreeQueryParam = `?step_free=${
      stepFreeRoute ? QueryParamBinary.ENABLED : QueryParamBinary.DISABLED
    }`;
    let urlParams = `${fromId}/${toId}`;

    if (routingUserActiveFloor) {
      if (isUsingFromCurrentLocation)
        urlParams = `${fromId},${routingUserActiveFloor.short_name}/${toId}`;
      if (isUsingToCurrentLocation)
        urlParams = `${fromId}/${toId},${routingUserActiveFloor.short_name}`;
    }

    if (ENVIRONMENT === "prod") {
      return `livingmap.link/r/${project}/${urlParams}/${stepFreeQueryParam}`;
    }

    return `shortlink-api.${ENVIRONMENT}.livingmap.com/v1/r/${project}/${urlParams}/${stepFreeQueryParam}`;
  };

  // If the origin or destination is changed in the url, update the input values
  useEffect(() => {
    if (query) {
      state?.selectDestination ? setDestination(query) : setOrigin(query);
      return;
    }

    setOrigin(fromName === "-" ? "" : fromName);
    setDestination(toName === "-" ? "" : toName);
  }, [fromName, query, state?.selectDestination, toName]);

  // set the button text to the origin/destination value when the input is focused
  useEffect(() => {
    clearTimeout(blurTimeout); // clear the timeout if an input is focused
    if (originInputFocused) {
      setButtonText(`${origin}`);
    }

    if (destinationInputFocused) {
      setButtonText(`${destination}`);
    }
  }, [
    originInputFocused,
    destinationInputFocused,
    blurTimeout,
    origin,
    destination,
  ]);

  // Checks the location state selectDestination and then focuses the correct input when on the routing screen
  useEffect(() => {
    if (state?.selectDestination) {
      isOnRoutingScreen && destinationInputRef.current?.focus();
    } else {
      isOnRoutingScreen && originInputRef.current?.focus();
    }
  }, [
    destinationInputRef,
    originInputRef,
    state?.selectDestination,
    isOnRoutingScreen,
  ]);

  useEffect(() => {
    if (searchResults) {
      setShowSearchResults(true);
    }
  }, [searchResults]);

  useEffect(() => {
    if (isOnRouteScreen) {
      setOriginInputFocused(false);
      setDestinationInputFocused(false);
    }
  }, [isOnRouteScreen, setDestinationInputFocused, setOriginInputFocused]);

  useEffect(() => {
    if (
      !originInputFocused &&
      !destinationInputFocused &&
      isOnRoutingScreen &&
      inputCleared
    ) {
      if (state?.selectDestination) {
        destinationInputRef.current?.focus();
        setDestinationInputFocused(true);
      } else {
        originInputRef.current?.focus();
        setOriginInputFocused(true);
      }

      setInputCleared(false);
    }
  }, [
    destinationInputFocused,
    destinationInputRef,
    inputCleared,
    isOnRoutingScreen,
    originInputFocused,
    originInputRef,
    setDestinationInputFocused,
    setOriginInputFocused,
    state?.selectDestination,
  ]);

  return Default && queryParamsConfig["ui-controls"] !== "hide" ? (
    <div className={styles.panelContainer}>
      <Panel dataQA="desktop-routing-panel" className={styles.container}>
        <div>
          <RouteInput
            dataQA="desktop-routing-input"
            origin={getOriginRouteData()}
            destination={getDesintationRouteData()}
            onChooseDestination={() => handleChoosePoi(true)}
            onChooseOrigin={() => handleChoosePoi(false)}
            onSwapButtonClick={handleSwapButtonClick}
            onOriginChange={handleOriginChange}
            onDestinationChange={handleDestinationChange}
            onClearClick={handleClearInputValue}
            onSubmit={handleOnSearchSubmit}
          />
          {stepFreeToggleComponent && mapData?.routing?.step_free_enabled ? (
            <div className={styles.stepFreeToggle}>
              {stepFreeToggleComponent}
            </div>
          ) : null}
        </div>
        <Icon
          type="CloseIcon"
          dataQA="desktop-routing-close-icon"
          className={styles.closeIcon}
          onClick={handleCloseButtonClick}
        />
      </Panel>
      <Panel
        dataQA="desktop-routing-panel-results"
        className={classNames(styles.desktopQuickActionsPanel, {
          [styles.hidden]: routeMilestones || showSearchResults,
        })}
      >
        {buttonText && buttonText.length > 1 ? (
          <>
            {searchSuggestionsList}
            <ListItemResult
              dataQA="submit-route-poi-search"
              title={
                <Trans
                  i18nKey="home.search_suggestion_text"
                  values={{ query: truncateString(buttonText, 25) }}
                />
              }
              color={COLOURS.BLUE}
              icon="SearchIcon"
              className={styles.searchResult}
              onClick={() => {
                if (originInputFocused && origin) {
                  handleOnSearchSubmit(origin, false);
                } else if (destinationInputFocused && destination) {
                  handleOnSearchSubmit(destination, true);
                }
              }}
            />
          </>
        ) : (
          <RoutingQuickActions
            showQuickActions={
              !isUsingFromCurrentLocation && !isUsingToCurrentLocation
            }
            recentItemsLimit={4}
          />
        )}
      </Panel>
      <Panel
        dataQA="desktop-routing-search-results"
        className={classNames(styles.searchResultsContainer, {
          [styles.hidden]:
            !query || isFetchingSearchResults || !showSearchResults,
        })}
      >
        <SearchResults
          className={styles.searchResults}
          dataQA="desktop-routing-search-results"
          searchResults={searchResults}
          language={language}
          query={query || ""}
          onSearchResultClick={onSearchResultClick}
        />
      </Panel>
      {!isFetchingRoute &&
      routeMilestones &&
      totalTime !== undefined &&
      isRouteSuccess ? (
        <CollapsablePanel
          dataQA="desktop-route-segments"
          header={timeToDestinationComponent}
          contentClassName={styles.collapsablePanelContent}
          footer={
            <Button
              dataQA="desktop-send-directions-route-button"
              onClick={() => setIsShareDialogOpen(true)}
              icon="ShareDirectionsIcon"
              color="black"
              outlined
              rounded
              className={styles.sendDirectionsButton}
            >
              {t("navigate_page.send_directions_to_mobile")}
            </Button>
          }
          footerClassName={styles.collapsablePanelFooter}
          isOpen
        >
          <RouteContent
            routeMilestones={routeMilestones}
            totalTime={totalTime}
            displayUnit={mapData?.routing?.display_unit_system || "metric"}
            language={language}
            showTime={
              mapData &&
              showRouteTime(
                mapData.routing?.time_to_destination_enabled,
                mapData.routing?.time_to_destination_limit,
                totalTime,
              )
            }
            showStepOption={mapData?.routing?.directions_enabled}
          />
        </CollapsablePanel>
      ) : null}
      {isFetchingRoute === false && isRouteSuccess === false && mapLoaded ? (
        <Panel
          dataQA="desktop-routing-error"
          className={styles.routingErrorPanel}
        >
          {t("navigate_page.no_results")}
        </Panel>
      ) : null}
      {isFetchingRoute && chipContainerRef && (
        <LoadingChip
          chipContainerRef={chipContainerRef}
          isFetching={isFetchingRoute}
          title={t("navigate_page.finding_route_pill")}
        />
      )}
      <ShareDialog
        dataQA="feature-share-dialog"
        isOpen={isShareDialogOpen}
        url={shareUrl()}
        description={t("share_dialog.body")}
        onClose={() => setIsShareDialogOpen(false)}
      />
    </div>
  ) : null;
};

export default DesktopRoutingView;
