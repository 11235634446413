import { useCallback, useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import useRecentQueries from "../../hooks/useRecentQueries";

import { RECENT_POIS_KEY } from "../../constants";
import { buildRoutingPath } from "../../utils/buildRoutingPath";
import { push } from "../../utils/navigate";
import { parseLanguageObject } from "../../utils/parseLanguageObject";
import { QueryParams, RoutingPath } from "../../utils/types";

import useSearchResults from "../SearchResultsView/hooks/useSearchResults";
import MobileRoutingView from "./views/MobileRoutingView/MobileRoutingView";
import DesktopRoutingView from "./views/DesktopRoutingView/DesktopRoutingView";
import { MapContext } from "../../contexts/MapContext";
import createLMFeature from "../../utils/createLMFeature";

export default function RoutingView() {
  const {
    floorControl,
    routeFeature: featureFromMap,
    clearRouteFeature: clearFeatureId,
    mapLoaded,
    clusteredPinControl,
    interactionControl,
  } = useContext(MapContext);

  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { fromName, fromId, toName, toId } = useParams();

  const { addRecentQuery } = useRecentQueries(RECENT_POIS_KEY, 10);

  const { query, featureData, isFetching } = useSearchResults({
    activeFloor: floorControl?.getActiveFloor(),
  });

  const { language } = useAppSelector((state) => state.application);

  const goToRouteView = useCallback(
    (id: number, name: string) => {
      dispatch(
        push({
          pathOrLocation: state?.selectDestination
            ? buildRoutingPath(RoutingPath.NAVIGATE, {
                fromName,
                fromId,
                toName: name,
                toId: id,
              })
            : buildRoutingPath(RoutingPath.NAVIGATE, {
                fromName: name,
                fromId: id,
                toName,
                toId,
              }),
          discardParams: [QueryParams.QUERY],
        }),
      );
    },
    [dispatch, fromId, fromName, state?.selectDestination, toId, toName],
  );

  const handleSearchResultClick = (id: number, name: string) => {
    goToRouteView(id, name);
  };

  useEffect(() => {
    clusteredPinControl?.clearClusteredPinSource();
    interactionControl?.deselectFeatures();

    if (featureData && featureData.length) {
      const lmFeatures = featureData.map((feature) => {
        return createLMFeature(feature, language);
      });

      clusteredPinControl?.updateClusteredPins(lmFeatures);
    }
  }, [clusteredPinControl, featureData, interactionControl, language]);

  // Checks if the user has selected a feature from the map and if so, will go straight to the route view
  useEffect(() => {
    if (featureFromMap) {
      const { id, name, address } = featureFromMap;

      addRecentQuery(id, { name, address });
      goToRouteView(id, name);
      clearFeatureId();
    }
  }, [addRecentQuery, clearFeatureId, featureFromMap, goToRouteView]);

  // Checks if the user has done a search which returns a result of 1 item, and if so, will go straight to the route view
  useEffect(() => {
    if (featureData?.length === 1) {
      const {
        uid,
        street_address,
        information: { long_name },
      } = featureData[0];
      const name = long_name
        ? parseLanguageObject(long_name, language) || ""
        : "";

      addRecentQuery(uid, { name, address: street_address || "" });
      goToRouteView(uid, name);
    }
  }, [addRecentQuery, featureData, goToRouteView, language]);

  return (
    <>
      <DesktopRoutingView
        query={query}
        searchResults={featureData}
        isFetchingSearchResults={isFetching}
        onSearchResultClick={handleSearchResultClick}
        mapLoaded={mapLoaded}
      />
      <MobileRoutingView
        query={query}
        searchResults={featureData}
        isFetchingSearchResults={isFetching}
        onSearchResultClick={handleSearchResultClick}
      />
    </>
  );
}
