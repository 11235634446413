import {
  LocationOptions,
  MockGeolocationProvider,
} from "./MockGeolocationProvider";

let initialised = false;
let mockGeolocationProvider: MockGeolocationProvider | null = null;

export function initialiseGeolocationDebugger(
  setUserLocationCallback: () => void,
): MockGeolocationProvider | null {
  if (initialised) return mockGeolocationProvider;
  initialised = true;

  window.api = {
    ...window.api,
    setUserLocation: (
      userLocation: mapboxgl.LngLat,
      options?: LocationOptions | null,
    ) => {
      const updatedGeolocation = mockGeolocationProvider?.updateMockGeolocation(
        userLocation,
        options,
      );
      setUserLocationCallback();

      return updatedGeolocation;
    },
  };

  mockGeolocationProvider = new MockGeolocationProvider();

  console.log(
    "*Console user location enabled*\nUse api.setUserLocation({ lat: latitude, lng: longitude }) to update your location on the map.",
  );

  return mockGeolocationProvider;
}
