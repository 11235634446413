import LivingMap, {
  LivingMapPlugin,
  LMFeature,
  StateType,
} from "@livingmap/core-mapping";
import { Feature } from "geojson";
import { LngLatBounds } from "mapbox-gl";
import FloorControl from "./floor-control";
import { SourceIds } from "./types/index";

class ClusteredPinPlugin extends LivingMapPlugin {
  private features: LMFeature[] = [];
  private floorPlugin: FloorControl;
  private mapInstance?: mapboxgl.Map;

  public constructor(id: string, LMMap: LivingMap, floorPlugin: FloorControl) {
    super(id, LMMap);
    this.LMMap = LMMap;
    this.floorPlugin = floorPlugin;
  }

  activate(): void {
    this.mapInstance = this.LMMap.getMapboxMap();
    return;
  }

  public zoomToClusteredPins(
    features: LMFeature[],
    padding?: { top: number; bottom: number; left: number; right: number },
  ): void {
    const map = this.LMMap.getMapboxMap();

    // Create an empty bounds object
    const bounds = new LngLatBounds();

    // Extend the bounds to include each marker's position
    features.forEach((feature) => {
      bounds.extend([feature.getCentroid()![0], feature.getCentroid()![1]]);
    });

    // Zoom the map to fit the bounds with some padding (e.g., 30 pixels)
    try {
      map.fitBounds(bounds, {
        padding: padding || 30,
        bearing: map.getBearing(),
        maxZoom: 19,
        linear: true,
      });
    } catch (e) {
      console.error(e);
    }
  }

  public updateClusteredPins(
    features: LMFeature[],
    ignoreFloorFiltering: boolean = false,
  ): void {
    if (!this.mapInstance?.getSource(SourceIds.CLUSTER_SOURCE_ID)) {
      throw new Error(
        `Cluster source missing from style, expecting source with name: ${SourceIds.CLUSTER_SOURCE_ID}`,
      );
    }

    this.features = features;

    const currentlySelectedFeature =
      this.LMMap.getFeatureStateDelegate().getFeatureForState(
        StateType.SELECTED,
      );

    const mapboxFeatures: Feature[] = this.features.map(
      (lmFeature: LMFeature) => {
        const feature = Object.assign(
          { properties: {} },
          lmFeature.getMapboxFeature(),
        );

        if (
          currentlySelectedFeature !== null &&
          currentlySelectedFeature.getId() === lmFeature.getId()
        ) {
          feature.properties.selected = "active";
        } else {
          feature.properties.selected = "inactive";
        }

        const centroid = lmFeature.getCentroid();
        if (!centroid)
          throw new Error(
            `Centroid does not exist on LMFeature for: ${lmFeature.getId()}`,
          );

        feature.geometry = {
          type: "Point",
          coordinates: centroid,
        };

        feature.properties = {
          ...feature.properties,
          preventClickPropagation: true,
        };

        // If we are ignoring floor filtering, remove the floor_id and floor_name properties to render the pins on all floors
        if (ignoreFloorFiltering) {
          delete feature.properties.floor_id;
          delete feature.properties.floor_name;
        }

        // Mapbox doesn't handle null values correctly, so remove any property that is null to allow
        // pins to be displayed correctly
        for (const k in feature.properties) {
          if (feature.properties[k] === null) {
            delete feature.properties[k];
          }
        }

        return feature;
      },
    );

    const source = this.mapInstance?.getSource(
      SourceIds.CLUSTER_SOURCE_ID,
    ) as mapboxgl.GeoJSONSource;

    source.setData({
      type: "FeatureCollection",
      features: mapboxFeatures,
    });
  }

  public clearClusteredPinSource(): void {
    const source = this.mapInstance?.getSource(
      SourceIds.CLUSTER_SOURCE_ID,
    ) as mapboxgl.GeoJSONSource;
    if (source) {
      this.features = [];
      source.setData({
        type: "FeatureCollection",
        features: [],
      });
      this.reloadClusteredPins();
    }
  }

  public reloadClusteredPins(): void {
    this.updateClusteredPins(this.features);
  }
}

export default ClusteredPinPlugin;
