import { useEffect } from "react";
import { useAppDispatch } from "../redux/hooks";
import { useGetMapConfigQuery } from "../redux/services/vectormapsAPI";
import { MapOptions } from "../redux/services/types";
import { setMapOptionsConfig } from "../redux/slices/applicationSlice";

const useMapOptionsConfig = () => {
  const { data } = useGetMapConfigQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data && data.map_options) {
      const { directions, search, user_location, geofences } = data.map_options;

      const mapOptionsConfig: MapOptions = {
        directions,
        search,
        user_location,
        geofences,
      };

      dispatch(setMapOptionsConfig(mapOptionsConfig));
    }
  }, [data, dispatch]);
};

export default useMapOptionsConfig;
