import { Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";

import { OperatingHours } from "../../redux/services/types";

import { isLTR } from "../../utils/getLanguageDir";

import { formatTime } from "../../hooks/formatTime";
import { useCalculateOperatingHours } from "../../hooks/useCalculateOperatingHours";

import { Status } from "./types";

import MetaData from "../MetaData/MetaData";
import OpenTimeSpan from "../OpenTimeSpan/OpenTimeSpan";

import styles from "./OpenTimeInfo.module.scss";

interface Props {
  dataQA: string;
  isTemporarilyClosed: boolean;
  operatingHours: OperatingHours;
  className?: string;
}

const OpenTimeInfo = ({
  dataQA,
  isTemporarilyClosed,
  operatingHours,
  className,
}: Props) => {
  const localRef = useRef<HTMLDListElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [animatedHeight, animateHeight] = useSpring(() => ({}));
  const { t } = useTranslation();

  useEffect(() => {
    animateHeight.start({
      opacity: isOpen ? 1 : 0,
      height: isOpen ? `${localRef.current!.clientHeight + 10}px` : "0", // 10px to account for margin that is cropped off
    });
  }, [animateHeight, localRef, isOpen]);

  const { todaysDay, checkIsActiveOpeningTime } =
    useCalculateOperatingHours(operatingHours);

  return (
    <div data-qa={dataQA} className={classNames(styles.container, className)}>
      <MetaData icon="HoursIcon" dataQA="open-time-info">
        {isTemporarilyClosed ? (
          <OpenTimeSpan
            dataQA="open-time-span"
            operatingHours={operatingHours}
            isTemporarilyClosed={isTemporarilyClosed}
          />
        ) : (
          <div className={styles.infoContainer}>
            <button
              className={styles.title}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <OpenTimeSpan
                dataQA="open-time-span"
                operatingHours={operatingHours}
              />
              <Icon
                className={styles.chevron}
                type={isOpen ? "ChevronUpIcon" : "ChevronDownIcon"}
                dataQA="open-time-info-icon"
              />
            </button>
          </div>
        )}
      </MetaData>
      {!isTemporarilyClosed && operatingHours && (
        <animated.div
          style={{ overflow: "hidden", height: 0, ...animatedHeight }}
        >
          <dl className={styles.daysInfo} ref={localRef}>
            {Object.entries(operatingHours).map(([day, dayOperatingHours]) => (
              <div key={day} className={styles.dayRow}>
                <dt
                  className={classNames(styles.dayLabel, {
                    [styles.active]: day === todaysDay,
                  })}
                >
                  {t(`operating_hours_day_${day}`)}
                </dt>
                <dd className={styles.dayTimes}>
                  {dayOperatingHours ? (
                    dayOperatingHours.map((hours, index) => (
                      <span
                        key={index}
                        className={classNames({
                          [styles.active]:
                            day === todaysDay &&
                            checkIsActiveOpeningTime(hours),
                        })}
                      >
                        {isLTR()
                          ? `${formatTime(hours.opening_time)} – ${formatTime(hours.closing_time)}`
                          : `${formatTime(hours.closing_time)} – ${formatTime(hours.opening_time)}`}
                      </span>
                    ))
                  ) : (
                    <span
                      className={classNames({
                        [styles.active]: day === todaysDay,
                      })}
                    >
                      {t(`operating_hours_status_${Status.CLOSED}`)}
                    </span>
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </animated.div>
      )}
    </div>
  );
};

export default OpenTimeInfo;
