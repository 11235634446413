import {
  useGetFeaturesBySearchQuery,
  useGetFeaturesByTagSearchQuery,
  useGetFeaturesByNameQuery,
} from "../../../redux/services/vectormapsAPI";
import { useAppSelector } from "../../../redux/hooks";
import { useFeatureSearchParams } from "../../../hooks/useFeatureSearchParams";

import { FloorConfig } from "./../../../components/Map/plugins/floor-control";
import { useContext, useEffect, useRef } from "react";
import { MapContext } from "../../../contexts/MapContext";

const useSearchResults = ({
  activeFloor,
}: {
  quickSearch?: boolean;
  nameSearch?: boolean;
  activeFloor?: FloorConfig | null;
}) => {
  const { project, language } = useAppSelector((state) => state.application);
  const { mapCentre } = useContext(MapContext);
  const { query, isQuickSearch, isNameSearch } = useFeatureSearchParams();

  const previousQuery = useRef<string | null>(null);
  const previousTag = useRef<string | null>(null);

  const sharedParams = {
    project,
    lang: language,
    ...(mapCentre &&
      activeFloor && {
        latitude: mapCentre.lat,
        longitude: mapCentre.lng,
        floor_id: activeFloor.id,
      }),
  };

  const {
    isSuccess: isSuccessBySearchQuery,
    isFetching: isFetchingBySearchQuery,
    data: featuresBySearchQuery,
  } = useGetFeaturesBySearchQuery(
    { query, ...sharedParams },
    {
      skip:
        isQuickSearch ||
        isNameSearch ||
        !query ||
        !project ||
        query === previousQuery.current,
    },
  );

  const { isFetching: isFetchingByNameQuery, data: featuresByNameQuery } =
    useGetFeaturesByNameQuery(
      { longName: query, ...sharedParams },
      { skip: isQuickSearch || !isNameSearch || !query || !project },
    );

  const {
    isSuccess: isSuccessBySearchTag,
    isFetching: isFetchingBySearchTag,
    data: featuresBySearchTag,
  } = useGetFeaturesByTagSearchQuery(
    { id: query, ...sharedParams },
    {
      skip:
        !isQuickSearch ||
        isNameSearch ||
        !query ||
        !project ||
        !activeFloor ||
        !mapCentre ||
        query === previousTag.current,
    },
  );

  useEffect(() => {
    if (isSuccessBySearchQuery && !isFetchingBySearchQuery)
      previousQuery.current = query;
    if (isSuccessBySearchTag && !isFetchingBySearchTag)
      previousTag.current = query;
  }, [
    isFetchingBySearchQuery,
    isSuccessBySearchQuery,
    isFetchingBySearchTag,
    isSuccessBySearchTag,
    query,
  ]);

  return {
    query,
    featureData: isQuickSearch
      ? featuresBySearchTag?.data
      : isNameSearch
        ? featuresByNameQuery?.data
        : featuresBySearchQuery?.data,
    isFetching: isQuickSearch
      ? isFetchingBySearchTag
      : isNameSearch
        ? isFetchingByNameQuery
        : isFetchingBySearchQuery,
  };
};

export default useSearchResults;
