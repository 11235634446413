import {
  addDays,
  differenceInHours,
  format,
  isBefore,
  isWithinInterval,
} from "date-fns";
import { useCallback, useMemo } from "react";
import { Status } from "../components/OpenTimeInfo/types";
import { OperatingHours, Day, DayInfo } from "../redux/services/types";
import { convertTimeToDate } from "../utils";
import { formatTime } from "./formatTime";
import { useTranslation } from "react-i18next";

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
  );
}

export const useCalculateOperatingHours = (operatingHours: OperatingHours) => {
  const currentDate = useMemo(() => new Date(), []);
  const { t } = useTranslation();

  const todaysDay = format(currentDate, "eeee").toLowerCase() as Day;
  const todaysOpeningHours = operatingHours && operatingHours[todaysDay];

  const checkIsActiveOpeningTime = useCallback(
    (dayOperatingHours: DayInfo) => {
      const openingTime = convertTimeToDate(dayOperatingHours.opening_time);
      const closingTime = convertTimeToDate(dayOperatingHours.closing_time);

      // if closing time is before opening time, then it's open overnight and we need to account for 2 intervals
      if (closingTime < openingTime) {
        return !isWithinInterval(currentDate, {
          start: closingTime,
          end: openingTime,
        });
      }

      return isWithinInterval(currentDate, {
        start: openingTime,
        end: closingTime,
      });
    },
    [currentDate],
  );

  const activeTimeIndex = useMemo(
    () =>
      todaysOpeningHours?.findIndex((openingHours) =>
        checkIsActiveOpeningTime(openingHours),
      ) ?? -1,
    [todaysOpeningHours, checkIsActiveOpeningTime],
  );

  const status = useMemo(() => {
    if (!todaysOpeningHours || activeTimeIndex === -1) return Status.CLOSED;

    const openingTime = convertTimeToDate(
      todaysOpeningHours[activeTimeIndex].opening_time,
    );
    const closingTime = convertTimeToDate(
      todaysOpeningHours[activeTimeIndex].closing_time,
    );

    if (differenceInHours(closingTime, openingTime) === 24) {
      return Status.OPEN_24_HOURS;
    }

    return Status.OPEN;
  }, [todaysOpeningHours, activeTimeIndex]);

  const getNextOpeningTime = useCallback(() => {
    if (todaysOpeningHours) {
      const nextOpeningHoursIndex = todaysOpeningHours.findIndex(
        (openingHours) =>
          isBefore(currentDate, convertTimeToDate(openingHours.opening_time)),
      );

      if (nextOpeningHoursIndex !== -1) {
        return {
          day: todaysDay,
          time: todaysOpeningHours[nextOpeningHoursIndex].opening_time,
        };
      }
    }

    // Loop through the next 7 days to find the next available opening time
    for (let i = 1; i <= 7; i++) {
      const nextDay = addDays(currentDate, i);
      const day = format(nextDay, "eeee").toLowerCase() as Day;
      const nextDayOperatingHours = operatingHours && operatingHours[day];

      if (nextDayOperatingHours) {
        return {
          day,
          time: nextDayOperatingHours[0].opening_time,
        };
      }
    }
  }, [currentDate, operatingHours, todaysOpeningHours, todaysDay]);

  const statusCopy = useMemo(() => {
    if (operatingHours === null) return null;
    switch (status) {
      case Status.OPEN_24_HOURS:
        return t(`operating_hours_status_${Status.OPEN_24_HOURS}`);
      case Status.OPEN:
        if (todaysOpeningHours) {
          return t(`operating_hours_status_${Status.OPEN}`, {
            time: formatTime(todaysOpeningHours[activeTimeIndex].closing_time),
          });
        }
        break;
      case Status.CLOSED: {
        const nextOpening = getNextOpeningTime();
        return t(`operating_hours_status_${Status.CLOSED_UNTIL}`, {
          day: nextOpening && toTitleCase(nextOpening?.day), // Include the next opening day
          time: formatTime(nextOpening?.time!), // Include the next opening time
        });
      }
      default:
        break;
    }
  }, [
    status,
    todaysOpeningHours,
    activeTimeIndex,
    getNextOpeningTime,
    t,
    operatingHours,
  ]);

  return {
    status,
    statusCopy,
    todaysDay,
    checkIsActiveOpeningTime,
  };
};
