import { useEffect } from "react";

import { RouteQueryResponse } from "./../redux/services/types/index";

import { generateFullRouteMilestones } from "./../utils/generateFullRouteMilestones";
import { generateFullRoute } from "../utils/generateFullRoute";

import RoutingPlugin from "../components/Map/plugins/routing-control";

interface Props {
  segments?: RouteQueryResponse["segments"];
  sequenceOrder?: number[];
  routingControl: RoutingPlugin | null;
  directionsEnabled?: boolean;
}

export const useDisplayRoute = ({
  segments,
  sequenceOrder,
  routingControl,
  directionsEnabled,
}: Props) => {
  const routeMilestones =
    segments && sequenceOrder
      ? generateFullRouteMilestones(sequenceOrder, segments, directionsEnabled) // combines all routeMilestones into one array
      : [];

  const zoomToRouteMilestone = (index: number) => {
    if (routeMilestones.length) {
      routingControl?.handleMapRouteMilestoneDisplay(routeMilestones[index]);
    }
  };

  const renderRoute = () => {
    if (segments && sequenceOrder) {
      const features = generateFullRoute(sequenceOrder, segments);
      const milestones = generateFullRouteMilestones(
        sequenceOrder,
        segments,
        directionsEnabled,
      );
      routingControl?.renderRoute(features, milestones);
    }
  };

  useEffect(renderRoute, [
    routingControl,
    segments,
    sequenceOrder,
    directionsEnabled,
  ]);

  return { zoomToRouteMilestone, routeMilestones, renderRoute };
};
