import { booleanPointInPolygon, point } from "@turf/turf";
import { Polygon } from "geojson";

import { Geofence, LatLng } from "../../../../redux/services/types";
import { FloorConfig } from "../floor-control";

export default class GeofenceArea {
  private geofence: Geofence;
  private currentCount = 1;
  private activationCount = 3;
  private areaTriggered: boolean;
  private triggerGeofenceCallback: () => void;

  constructor(geofence: Geofence, triggerGeofenceCallback: () => void) {
    this.geofence = geofence;
    this.areaTriggered = false;
    this.triggerGeofenceCallback = triggerGeofenceCallback;
  }

  public activateGeofenceIfUserIsInArea(
    userLocation: LatLng,
    activeFloor: FloorConfig | null,
  ): void {
    if (this.areaTriggered) return;
    if (this.isUserInArea(userLocation, activeFloor))
      this.checkGeofenceActivation();
    else this.currentCount = 1;
  }

  private checkGeofenceActivation(): void {
    if (this.currentCount === this.activationCount) {
      this.triggerGeofencefunction();
      return;
    } else if (this.currentCount < this.activationCount) this.currentCount++;
  }

  private triggerGeofencefunction(): void {
    this.areaTriggered = true;
    this.triggerGeofenceCallback();
  }

  private isUserInArea(
    userLocation: LatLng,
    activeFloor: FloorConfig | null,
  ): boolean {
    if (activeFloor?.id !== this.geofence.floor_id) return false;
    return this.isLocationInside(userLocation, this.geofence.geometry);
  }

  private isLocationInside(
    userLocationPoint: LatLng,
    geometry: Polygon,
  ): boolean {
    const locationPoint = point([
      userLocationPoint.latitude,
      userLocationPoint.longitude,
    ]);
    const isInsideBounds = booleanPointInPolygon(locationPoint, geometry);
    return isInsideBounds;
  }
}
