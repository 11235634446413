import { useContext, useEffect } from "react";
import { GlobalFilters, Expressions } from "@livingmap/core-mapping";
import { useLocation } from "react-router-dom";

import { usePostAnalyticsEvent } from "../../hooks/usePostAnalyticsEvent";
import useRecentQueries from "../../hooks/useRecentQueries";
import { useAppDispatch } from "../../redux/hooks";
import { QueryParamBinary } from "../../utils/types";

import { AnalyticsEvent } from "../../utils/analyticsTypes";
import { push } from "../../utils/navigate";
import { QueryParams } from "../../utils/types";

import { RECENT_SEARCHES_KEY } from "../../constants";

import { MapContext } from "../../contexts/MapContext";

import DesktopHomeView from "./views/DesktopHomeView/DesktopHomeView";
import MobileHomeView from "./views/MobileHomeView/MobileHomeView";

const { not, equals, get } = Expressions;

export default function HomeView() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    interactionControl,
    floorControl,
    clusteredPinControl,
    filterControl,
  } = useContext(MapContext);
  const { logAnalyticsEvent } = usePostAnalyticsEvent();

  const { addRecentQuery } = useRecentQueries(RECENT_SEARCHES_KEY);

  const handleClickSearchSuggestion = (query: string, nameSearch: boolean) => {
    addRecentQuery(query, { quickSearch: false });

    logAnalyticsEvent({
      event_type: AnalyticsEvent.SEARCH_QUERY_SEND,
      event_data: { query, quick_search: false, quick_search_id: null },
    });
    if (nameSearch) {
      dispatch(
        push({
          pathOrLocation: "/search",
          newQueryParams: {
            [QueryParams.QUERY]: query,
            [QueryParams.NAME_SEARCH]: QueryParamBinary.ENABLED,
          },
          discardParams: [QueryParams.FEATURE_ID, QueryParams.QUICK_SEARCH],
        }),
      );
    } else {
      dispatch(
        push({
          pathOrLocation: "/search",
          newQueryParams: {
            [QueryParams.QUERY]: query,
          },
          discardParams: [
            QueryParams.FEATURE_ID,
            QueryParams.QUICK_SEARCH,
            QueryParams.NAME_SEARCH,
          ],
        }),
      );
    }
  };

  const activeFloor = floorControl?.getActiveFloor();

  useEffect(() => {
    if (location.pathname === "/") {
      interactionControl?.deselectFeatures();
      clusteredPinControl?.clearClusteredPinSource();
    }

    // we want to refresh the clustered pins when the floor changes
    clusteredPinControl?.reloadClusteredPins();

    if (filterControl && activeFloor) {
      filterControl.updateLocalFilter("search-search_pin-other_floor", {
        filter: [not(equals(get("floor_id"), activeFloor.id))] as any,
        globalExclusions: [GlobalFilters.FLOOR],
      });

      filterControl.updateLocalFilter("search-search_pin", {
        filter: [equals(get("floor_id"), activeFloor.id)] as any,
        globalExclusions: [GlobalFilters.FLOOR],
      });
    }
  }, [
    activeFloor,
    clusteredPinControl,
    filterControl,
    floorControl,
    interactionControl,
    location.pathname,
  ]);

  return (
    <>
      <DesktopHomeView onClickSearchSuggestion={handleClickSearchSuggestion} />
      <MobileHomeView onClickSearchSuggestion={handleClickSearchSuggestion} />
    </>
  );
}
