import { FloorConfig } from "./../../components/Map/plugins/floor-control";
import { LocationStatus } from "./../../components/LocationButton/LocationButton";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Location } from "react-router-dom";
import { MapOptions } from "../services/types";
import { FALLBACK_LANGUAGE } from "../../constants";

export type uiControlsConfig = "show" | "hide";
export type mapGesturesConfig = "enable" | "disable";
export type uiSearchConfig = "show" | "hide";
export type uiFeatureConfig = "show" | "hide";
export type geoLocationConfig = "enable" | "disable";
export type DebugConfig = "enable" | "disable";
export type consoleLocationConfig = "enable" | "disable";
export type floorConfig = string;
export type MarkerConfig = string | null;
export type CallbackConfig = string | null;
export type PluginConfig = string;
export type sourceConfig = string;

export type QueryParamsConfig = {
  "ui-controls": uiControlsConfig;
  "ui-gestures": mapGesturesConfig;
  "ui-search": uiSearchConfig;
  "ui-feature": uiFeatureConfig;
  geolocation: geoLocationConfig;
  floor: floorConfig;
  consoleLocation: consoleLocationConfig;
  markers?: MarkerConfig;
  callback?: CallbackConfig;
  plugin?: PluginConfig;
  debug?: DebugConfig;
  source?: sourceConfig;
};

export type DeviceInformation = {
  os: string | undefined;
  osVersion: string | undefined;
};

export interface ApplicationState {
  savedLocation: Location | null;
  queryParamsConfig: QueryParamsConfig;
  mapOptionsConfig: MapOptions;
  locationStatus: LocationStatus;
  language: string;
  project?: string;
  // we need to keep track of what the user has selected as the active floor when beginning a route
  // to prevent the floor from changing when the the floor in the url updates
  routingUserActiveFloor?: FloorConfig;
  sessionId?: string;
  deviceInformation: DeviceInformation;
  triggeredGeofenceId: string | null;
}

const initialState: ApplicationState = {
  savedLocation: null,
  queryParamsConfig: {
    "ui-controls": "show",
    "ui-gestures": "enable",
    "ui-search": "show",
    "ui-feature": "show",
    geolocation: "enable",
    plugin: "",
    debug: "disable",
    consoleLocation: "disable",
    floor: "",
    markers: null,
    callback: null,
  },
  mapOptionsConfig: {
    directions: false,
    search: false,
    user_location: false,
    geofences: false,
  },
  locationStatus: LocationStatus.INACTIVE,
  language: FALLBACK_LANGUAGE,
  deviceInformation: {
    os: undefined,
    osVersion: undefined,
  },
  triggeredGeofenceId: null,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    storeLocation: (state, action: PayloadAction<Location>) => {
      state.savedLocation = action.payload;
    },
    clearLocation: (state) => {
      state.savedLocation = null;
    },
    setQueryParamsConfig: (
      state,
      action: PayloadAction<Partial<QueryParamsConfig>>,
    ) => {
      state.queryParamsConfig = {
        ...state.queryParamsConfig,
        ...action.payload,
      };
    },
    setMapOptionsConfig: (
      state,
      action: PayloadAction<Partial<MapOptions>>,
    ) => {
      state.mapOptionsConfig = {
        ...state.mapOptionsConfig,
        ...action.payload,
      };
    },
    setLocationStatus: (
      state,
      action: PayloadAction<Partial<LocationStatus>>,
    ) => {
      state.locationStatus = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setProject: (state, action: PayloadAction<string>) => {
      state.project = action.payload;
    },
    setRoutingUserActiveFloor: (state, action: PayloadAction<FloorConfig>) => {
      state.routingUserActiveFloor = action.payload;
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    setDeviceInformation: (state, action: PayloadAction<DeviceInformation>) => {
      state.deviceInformation = action.payload;
    },
    setTriggeredGeofenceId: (state, action: PayloadAction<string | null>) => {
      state.triggeredGeofenceId = action.payload;
    },
  },
});

export const {
  clearLocation,
  storeLocation,
  setQueryParamsConfig,
  setMapOptionsConfig,
  setLocationStatus,
  setLanguage,
  setProject,
  setRoutingUserActiveFloor,
  setSessionId,
  setDeviceInformation,
  setTriggeredGeofenceId,
} = applicationSlice.actions;

export default applicationSlice.reducer;
