import { useRef, useEffect, useState, useContext } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import SwiperCore from "swiper";

import {
  clearLocation,
  storeLocation,
} from "../../redux/slices/applicationSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { usePostRouteData } from "../RouteView/hooks/usePostRouteData";
import { useDisplayRoute } from "../../hooks/useDisplayRoute";
import { useRedirectToDesktopRoute } from "../../hooks/useRedirectToDesktopRoute";
import { useGetRouteMilestonesProps } from "../../hooks/useGetRouteMilestonesProps";
import { useRoutingUserActiveFloor } from "../../hooks/useRoutingUserActiveFloor";

import { QueryParams } from "../../utils/types";
import { replace } from "../../utils/navigate";
import { showRouteTime } from "../../utils/showRouteTime";

import RouteStepSlider, {
  SwiperRef,
} from "../../components/RouteStepSlider/RouteStepSlider";
import TimeToDestination from "../../components/TimeToDestination/TimeToDestination";

import styles from "./JourneyView.module.scss";
import { MapContext } from "../../contexts/MapContext";
import { useGetMapConfigQuery } from "../../redux/services/vectormapsAPI";

export default function JourneyView() {
  const { floorControl, routingControl } = useContext(MapContext);

  useRedirectToDesktopRoute();
  useRoutingUserActiveFloor({
    getActiveFloor: () => floorControl?.getActiveFloor(),
  });

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { fromId, toId } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const { language, routingUserActiveFloor, savedLocation } = useAppSelector(
    (state) => state.application,
  );

  const slideIndex = Number(searchParams.get(QueryParams.MILESTONE_INDEX)) || 0;

  const sliderRef = useRef<SwiperRef | null>(null);

  const [init, setInit] = useState(false);

  const { data: mapData } = useGetMapConfigQuery();

  const { data: routeData, isError } = usePostRouteData({
    fromId,
    toId,
    floorId: routingUserActiveFloor?.id,
  });

  const { zoomToRouteMilestone, routeMilestones } = useDisplayRoute({
    routingControl,
    segments: routeData?.segments,
    sequenceOrder: routeData?.routeMetadata[0].sequenceOrder,
    directionsEnabled: mapData?.routing?.directions_enabled,
  });
  const routeMilestonesProps = useGetRouteMilestonesProps({
    routeMilestones,
    totalTime: routeData?.routeMetadata[0].totalTime,
    displaySteps: true,
    displayUnit: mapData?.routing?.display_unit_system || "metric",
    language,
  });

  const handleOnSlideChange = (slide: SwiperCore) => {
    const { activeIndex } = slide;

    zoomToRouteMilestone(activeIndex);

    dispatch(
      replace({
        pathOrLocation: pathname,
        newQueryParams: { [QueryParams.MILESTONE_INDEX]: activeIndex },
      }),
    );
  };

  useEffect(() => {
    if (!init && sliderRef.current?.swiper) {
      sliderRef.current.swiper.slideTo(slideIndex);
      zoomToRouteMilestone(slideIndex);
      setInit(true);
    }
  }, [init, slideIndex, zoomToRouteMilestone]);

  useEffect(() => {
    dispatch(storeLocation(location));
  }, [dispatch, location]);

  useEffect(() => {
    if (isError)
      dispatch(
        replace({
          pathOrLocation: "/",
          discardParams: [QueryParams.STEP_FREE, QueryParams.MILESTONE_INDEX],
        }),
      );
  }, [dispatch, isError, savedLocation]);

  return routeData && mapData ? (
    <>
      <TimeToDestination
        skipGo
        dataQA={"journey-time-to-destination"}
        className={styles.timeToDestination}
        currentStep={slideIndex}
        kilometres={routeData.routeMetadata[0].totalLength}
        displayUnit={mapData.routing?.display_unit_system || "metric"}
        minutes={routeData.routeMetadata[0].totalTime}
        live={fromId?.includes(",") || toId?.includes(",")}
        steps={routeMilestones.length}
        showTime={showRouteTime(
          mapData.routing?.time_to_destination_enabled,
          mapData.routing?.time_to_destination_limit,
          routeData.routeMetadata[0].totalTime,
        )}
        onClick={() => {
          dispatch(clearLocation());
          routingControl!.setJourneyMode(false);
          dispatch(
            replace({
              pathOrLocation: "/",
              discardParams: [
                QueryParams.STEP_FREE,
                QueryParams.MILESTONE_INDEX,
              ],
            }),
          );
        }}
      />
      <RouteStepSlider
        className={styles.routeStepSlider}
        sliderRef={sliderRef}
        dataQA={"route-step-slider"}
        slides={routeMilestonesProps}
        // onChange & onClick does the same thing at the moment
        onChange={handleOnSlideChange}
        onClick={handleOnSlideChange}
        showTime={showRouteTime(
          mapData.routing?.time_to_destination_enabled,
          mapData.routing?.time_to_destination_limit,
          routeData.routeMetadata[0].totalTime,
        )}
      />
    </>
  ) : null;
}
