import {
  RouteMilestoneFeature,
  RouteQueryResponse,
} from "./../../../redux/services/types/index";
import LivingMap, {
  FilterKing,
  GlobalFilters,
  LivingMapPlugin,
  Utils,
} from "@livingmap/core-mapping";

import { Feature, MultiLineString, Point } from "geojson";
import mapboxgl from "mapbox-gl";
import { breakpoints } from "../../../hooks/useResponsive";
import { MappedFloor } from "../../../redux/services/types";
import FloorControl from "./floor-control";
import { EMPTY_DATA_SOURCE } from "./position-control";
import { EventTypes, LayerIds, PLUGIN_IDS, SourceIds } from "./types";
import { nearestPointOnLine } from "@turf/turf";

const {
  createGeoJSONFeature,
  createGeoJSONFeatureCollection,
  createGeoJSONGeometryPoint,
} = Utils;

interface RoutePointProperties {
  route_icon: string;
  floor_id?: number;
}

interface Coordinate {
  x: number;
  y: number;
  floorId?: number;
}

// A larger divisor will result in a slower animation
const ROUTE_ANIMATION_STEP_DIVISOR = 100;
// A larger multiplier will result in a longer gradient on the route line on long routes
const ROUTE_ANIMATION_STEP_MULTIPLIER = 20;
// Used to ensure that steps aren't too quick on long routes
const ROUTE_ANIMATION_MAX_STEP = 5;

class RoutingPlugin extends LivingMapPlugin {
  private floorControl: FloorControl | null = null;
  private floors: MappedFloor[] | null = null;
  private filterInstance: FilterKing;
  private handleFloorChangeCallback: (floor: MappedFloor) => void;
  private animationFrameInstance: number = 0;
  private currentRoute: Feature<MultiLineString, {}> | undefined;
  private journeyMode: boolean = false;

  public constructor(
    id: string,
    LMMap: LivingMap,
    floors: MappedFloor[],
    floorChangeCallback?: (floor: MappedFloor) => void,
  ) {
    super(id, LMMap);
    this.filterInstance = LMMap.getFilterKing();
    this.floors = floors;
    this.handleFloorChangeCallback =
      floorChangeCallback?.bind(this) || (() => null);
  }

  public activate(): void {
    this.floorControl = this.LMMap.getPluginById<FloorControl>(
      PLUGIN_IDS.FLOOR,
    );

    this.LMMap.on(EventTypes.FLOOR_CHANGED, this.handleFloorChange);
  }

  public deactivate(): void {
    this.LMMap.removeListener(EventTypes.FLOOR_CHANGED, this.handleFloorChange);
    this.clear();
  }

  private setRouteVisualisation(): void {
    // display the routing line with blue and grey
    this.filterInstance.updateLocalFilter(LayerIds.ROUTE_LINE_OTHER_LAYER, {
      globalExclusions: [GlobalFilters.FLOOR],
    });
    this.filterInstance.updateLocalFilter(
      `${LayerIds.ROUTE_LINE_OTHER_LAYER}-base`,
      { globalExclusions: [GlobalFilters.FLOOR] },
    );
  }

  public renderRoute(
    routeGeoJson: RouteQueryResponse["segments"][number]["routeGeoJson"],
    routeMilestones: RouteMilestoneFeature[],
  ) {
    this.clear();
    const origin = routeGeoJson[0];
    const originCoordinates: Coordinate = {
      x: origin.geometry.coordinates[0][0],
      y: origin.geometry.coordinates[0][1],
      floorId: origin.properties.floorId,
    };

    const bounds = this.parseAndDisplayRoute(
      routeGeoJson,
      routeMilestones,
      originCoordinates,
    );

    this.handleMapDisplay(originCoordinates, bounds);
    this.handleRouteAnimation(routeGeoJson);
  }

  public clear(): void {
    cancelAnimationFrame(this.animationFrameInstance);
    const mapInstance = this.LMMap.getMapboxMap();
    const iconSources = mapInstance.getSource(
      SourceIds.ROUTE_ICON_SOURCE,
    ) as mapboxgl.GeoJSONSource;

    if (iconSources) iconSources.setData(EMPTY_DATA_SOURCE);

    const routeLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_SOURCE,
    ) as mapboxgl.GeoJSONSource;
    if (routeLine) routeLine.setData(EMPTY_DATA_SOURCE);

    const routeOtherLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_OTHER_SOURCE,
    ) as mapboxgl.GeoJSONSource;

    if (routeOtherLine) routeOtherLine.setData(EMPTY_DATA_SOURCE);

    const routeAnimatedLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_ANIMATED_SOURCE,
    ) as mapboxgl.GeoJSONSource;

    if (routeAnimatedLine) {
      routeAnimatedLine.setData(EMPTY_DATA_SOURCE);
    }
    this.currentRoute = undefined;
  }

  private handleFloorChange = (): void => {
    if (this.floors) {
      const floorIndex = this.floors.findIndex(
        (floor) => this.floorControl?.getActiveFloor()?.id === floor.id,
      )!;

      this.handleFloorChangeCallback(this.floors[floorIndex]);
    }

    this.setRouteVisualisation();
  };

  private generatePoint(point: Coordinate, icon: string): Feature {
    const startPointGeometry = createGeoJSONGeometryPoint([point.x, point.y]);
    const featureProperties: RoutePointProperties = { route_icon: icon };

    if (point.floorId !== null) {
      featureProperties.floor_id = point.floorId;
    }

    const feature = createGeoJSONFeature(featureProperties, startPointGeometry);

    return feature;
  }

  private parseAndDisplayRoute(
    geoJsonData: RouteQueryResponse["segments"][number]["routeGeoJson"],
    routeMilestones: RouteMilestoneFeature[],
    from: Coordinate,
  ): mapboxgl.LngLatBoundsLike {
    const mapInstance = this.LMMap.getMapboxMap();

    const iconSources = mapInstance.getSource(
      SourceIds.ROUTE_ICON_SOURCE,
    ) as mapboxgl.GeoJSONSource;
    const routeLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_SOURCE,
    ) as mapboxgl.GeoJSONSource;
    const routeOtherLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_OTHER_SOURCE,
    ) as mapboxgl.GeoJSONSource;

    const features: Feature[] = [];
    routeMilestones.forEach((routeMilestone) => {
      const coordinate: Coordinate = {
        x: routeMilestone.geometry.coordinates[0],
        y: routeMilestone.geometry.coordinates[1],
      };

      if (routeMilestone.properties.originId) {
        coordinate.floorId = routeMilestone.properties.originId;
      }

      features.push(
        this.generatePoint(coordinate, routeMilestone.properties.mapIcon),
      );
    });

    const startFeatureCollection = createGeoJSONFeatureCollection(features);
    iconSources.setData(startFeatureCollection);

    const bounds = new mapboxgl.LngLatBounds();

    const startLgnLat = new mapboxgl.LngLat(from.x, from.y);
    bounds.extend(startLgnLat);

    const endLngLat = new mapboxgl.LngLat(from.x, from.y);
    bounds.extend(endLngLat);

    const geoJsonDataWithFloor = geoJsonData.map((feature) => {
      feature.geometry.coordinates.forEach((lngLatPair) => {
        bounds.extend(lngLatPair);
      });

      return {
        ...feature,
        properties: {
          ...feature.properties,
          floor_id: feature.properties.floorId,
        },
      };
    });

    routeLine.setData({
      type: "FeatureCollection",
      features: geoJsonDataWithFloor,
    });
    routeOtherLine.setData({
      type: "FeatureCollection",
      features: geoJsonDataWithFloor,
    });

    // local storage of route for positional related updates
    this.currentRoute = this.generateMultiLineString(geoJsonDataWithFloor);

    const exportBounds: mapboxgl.LngLatBoundsLike = [
      bounds.getSouthWest(),
      bounds.getNorthEast(),
    ];

    return exportBounds;
  }

  private generateMultiLineString(
    geoJsonData: any,
  ): Feature<MultiLineString, {}> | undefined {
    if (geoJsonData) {
      return {
        type: "Feature",
        properties: {},
        geometry: {
          type: "MultiLineString",
          coordinates: geoJsonData.map((feature: any) => {
            return feature.geometry.coordinates;
          }),
        },
      };
    }
    return;
  }

  public isRouting() {
    return this.currentRoute && this.journeyMode ? true : false;
  }

  public setJourneyMode(state: boolean) {
    this.journeyMode = state;
  }

  public getNearestRoutePosition(point: Point) {
    if (!this.currentRoute) return;
    return nearestPointOnLine(this.currentRoute, point.coordinates);
  }

  public handleMapRouteMilestoneDisplay(feature: RouteMilestoneFeature): void {
    const coords = {
      x: feature.geometry.coordinates[0],
      y: feature.geometry.coordinates[1],
      floorId: feature.properties.floorId,
    };

    this.handleMapDisplay(coords);
  }

  private handleRouteAnimation(
    geoJsonData: RouteQueryResponse["segments"][number]["routeGeoJson"],
  ) {
    const mapInstance = this.LMMap.getMapboxMap();

    const animatedRouteLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_ANIMATED_SOURCE,
    ) as mapboxgl.GeoJSONSource;
    if (!animatedRouteLine) return;

    const featureCoordinates: [number, number][] = [];
    geoJsonData.forEach((feature) =>
      featureCoordinates.push(...feature.geometry.coordinates),
    );

    // The bigger the step size the faster the animation
    // This is to ensure that long routes are not animated too slowly and short routes are not animated too quickly
    const step = Math.min(
      featureCoordinates.length / ROUTE_ANIMATION_STEP_DIVISOR,
      ROUTE_ANIMATION_MAX_STEP,
    );

    // Position in the route
    let pos = 0;

    const animateRoute = () => {
      if (pos < featureCoordinates.length) {
        const feature = createGeoJSONFeature(
          {},
          {
            coordinates: featureCoordinates.slice(
              pos,
              pos + step * ROUTE_ANIMATION_STEP_MULTIPLIER,
            ),
            type: "LineString",
          },
        );
        animatedRouteLine.setData(createGeoJSONFeatureCollection([feature]));
        pos += step;
      } else {
        animatedRouteLine.setData(createGeoJSONFeatureCollection([]));
        pos = 0;
      }

      this.animationFrameInstance = requestAnimationFrame(animateRoute);
    };

    animateRoute();
  }

  public handleMapDisplay(
    fromCoordinate: Coordinate,
    bounds?: mapboxgl.LngLatBoundsLike,
  ): void {
    if (this.floorControl) {
      const activeFloorId = this.floorControl.getActiveFloor()!.id;

      if (fromCoordinate.floorId !== activeFloorId && this.floors) {
        const floorIndex = this.floors.findIndex(
          (floor) => floor.id === fromCoordinate.floorId,
        )!;
        this.floorControl.setActiveFloor(this.floors[floorIndex]);
      }
    }

    const mapInstance = this.LMMap.getMapboxMap();
    const bearing = mapInstance.getBearing();

    const isMobile = window.innerWidth <= breakpoints.Mobile;

    const padding = {
      top: isMobile ? 80 : 20,
      right: 20,
      bottom: isMobile ? 280 : 70,
      left: isMobile ? 20 : 400,
    };

    if (bounds) {
      mapInstance.fitBounds(bounds, {
        bearing,
        padding,
      });
    } else {
      mapInstance.easeTo({
        center: { lat: fromCoordinate.y, lng: fromCoordinate.x },
        zoom: 18,
        bearing,
      });
    }

    this.LMMap.emit(EventTypes.ROUTE_DISPLAYED);
  }
}

export default RoutingPlugin;
