import { IconButton, Toast } from "@livingmap/hippo";
import classNames from "classnames";

import styles from "./Advert.module.scss";

interface Props {
  dataQA: string;
  className?: string;
  imageUrl?: string;
  newTab?: boolean;
  adUrl: string;
  onClose: () => void;
  subtitle?: string;
  text?: string;
  title: string;
}

export default function Advert({
  dataQA,
  className,
  adUrl,
  onClose,
  title,
  imageUrl,
  newTab,
  subtitle,
  text,
}: Props) {
  return (
    <Toast
      dataQA={dataQA}
      className={classNames(styles.toast, className)}
      contentType="advert"
      content={
        <a
          href={adUrl}
          {...(newTab ? { target: "_blank" } : {})}
          rel="noopener noreferrer"
        >
          <div className={styles.adContainer}>
            <div className={styles.image}>
              {imageUrl && <img src={imageUrl} alt="advert" />}
            </div>
            <div className={styles.content}>
              <div className={classNames(styles.title, styles.text)}>
                {title}
              </div>
              {(text || subtitle) && (
                <div className={classNames(styles.subtitle, styles.text)}>
                  <span>{text}</span>
                  {text && subtitle ? ` · ${subtitle}` : `${subtitle}`}
                </div>
              )}
            </div>
          </div>
          <IconButton
            className={styles.closeButton}
            icon="close"
            size="regular"
            dataQA="close-advert"
            iconSize={24}
            type="primary"
            onClick={(e) => {
              e?.preventDefault();
              onClose();
            }}
          />
        </a>
      }
    />
  );
}
