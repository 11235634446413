import { useContext, useEffect, useState } from "react";
import { ToggleSwitch } from "@livingmap/core-ui-v2";
import { useLocation, useParams } from "react-router-dom";

import { usePostRouteData } from "./hooks/usePostRouteData";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useRoutingUserActiveFloor } from "../../hooks/useRoutingUserActiveFloor";
import { storeLocation } from "../../redux/slices/applicationSlice";
import { useDisplayRoute } from "../../hooks/useDisplayRoute";
import { usePostAnalyticsEvent } from "../../hooks/usePostAnalyticsEvent";

import DesktopRoutingView from "../RoutingView/views/DesktopRoutingView/DesktopRoutingView";
import MobileRouteView from "./views/MobileRouteView/MobileRouteView";

import RouteContent from "./components/RouteContent/RouteContent";
import TimeToDestination from "../../components/TimeToDestination/TimeToDestination";

import { AnalyticsEvent } from "../../utils/analyticsTypes";
import { push } from "../../utils/navigate";
import { buildRoutingPath } from "../../utils/buildRoutingPath";
import { QueryParamBinary, QueryParams, RoutingPath } from "../../utils/types";
import { showRouteTime } from "../../utils/showRouteTime";

import styles from "./RouteView.module.scss";
import { MapContext } from "../../contexts/MapContext";
import { useGetMapConfigQuery } from "../../redux/services/vectormapsAPI";
import { useTranslation } from "react-i18next";

export default function RouteView() {
  const {
    chipContainerRef,
    floorControl,
    routingControl,
    mapLoaded,
    clusteredPinControl,
  } = useContext(MapContext);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { fromName, fromId, toName, toId } = useParams();
  const { t } = useTranslation();

  const [clearedRoute, setClearedRoute] = useState(false);

  const { updateRoutingUserActiveFloor } = useRoutingUserActiveFloor({
    getActiveFloor: () => floorControl?.getActiveFloor(),
  });

  const { data: mapData } = useGetMapConfigQuery();

  const { language } = useAppSelector((state) => state.application);

  const {
    data: routeData,
    isFetching,
    isSuccess,
    isError,
    stepFreeRoute,
  } = usePostRouteData({
    fromId,
    toId,
    floorId: floorControl?.getActiveFloor()?.id,
  });

  const { routeMilestones, renderRoute } = useDisplayRoute({
    routingControl,
    segments: routeData?.segments,
    sequenceOrder: routeData?.routeMetadata[0].sequenceOrder,
    directionsEnabled: mapData?.routing?.directions_enabled,
  });

  const { logAnalyticsEvent } = usePostAnalyticsEvent();

  useEffect(() => {
    if (isSuccess && routeMilestones) {
      clusteredPinControl?.clearClusteredPinSource();
      dispatch(storeLocation(location));
    }
  }, [clusteredPinControl, dispatch, isSuccess, location, routeMilestones]);

  // If the returned route is an error, clear the routing control
  // then if the route has been cleared we will rerender the route otherwise we won't
  useEffect(() => {
    if (isError) {
      routingControl?.clear();
      setClearedRoute(true);
    } else {
      if (clearedRoute) renderRoute();
      setClearedRoute(false);
    }
  }, [isError, routingControl, renderRoute, clearedRoute]);

  useEffect(() => {
    if (fromName && fromId && toName && toId) {
      const splitFromId = fromId?.split(",");
      const splitToId = toId?.split(",");

      const fromData =
        splitFromId?.length === 2
          ? {
              from_latitude: Number(splitFromId[1]),
              from_longitude: Number(splitFromId[0]),
            }
          : {
              from_uid: Number(fromId),
              from_name: fromName,
            };

      const toData =
        splitToId?.length === 2
          ? {
              to_latitude: Number(splitToId[1]),
              to_longitude: Number(splitToId[0]),
            }
          : { to_uid: Number(toId), to_name: toName };

      logAnalyticsEvent({
        event_type: AnalyticsEvent.ROUTE_REQUEST,
        event_data: { ...fromData, ...toData, step_free: stepFreeRoute },
      });
    }
  }, [fromName, fromId, toName, toId, logAnalyticsEvent, stepFreeRoute]);

  // StepFreeToggle component to be used in mobile/desktop view
  const StepFreeToggleComponent = () => (
    <ToggleSwitch
      dataQA="avoid-stairs-toggle-switch"
      label={t("navigate_page.avoid_stairs_toggle")}
      onToggle={() => {
        dispatch(
          push({
            pathOrLocation: location.pathname,
            newQueryParams: {
              [QueryParams.STEP_FREE]: stepFreeRoute
                ? QueryParamBinary.DISABLED
                : QueryParamBinary.ENABLED,
            },
          }),
        );
      }}
      isToggled={stepFreeRoute}
      isDisabled={isFetching}
    />
  );

  const showTime =
    routeData &&
    mapData &&
    showRouteTime(
      mapData.routing?.time_to_destination_enabled,
      mapData.routing?.time_to_destination_limit,
      routeData.routeMetadata[0].totalTime,
    );

  const TimeToDestinationComponent = ({
    showGoButton = true,
    className,
  }: {
    showGoButton?: boolean;
    className?: string;
  }) =>
    routeData && mapData ? (
      <TimeToDestination
        dataQA="route-time-to-destination"
        kilometres={routeData.routeMetadata[0].totalLength}
        displayUnit={mapData.routing?.display_unit_system || "metric"}
        minutes={routeData.routeMetadata[0].totalTime}
        steps={routeMilestones.length}
        currentStep={0}
        live={fromId?.includes(",") || toId?.includes(",")}
        arrived={false}
        showGoButton={showGoButton}
        className={className}
        showTime={showTime}
        onClick={() => {
          // we set the routingUserActiveFloor here before clicking 'GO' to ensure that the floor won't change
          // when continuing throughout the rest of the routing screens
          updateRoutingUserActiveFloor();
          routingControl!.setJourneyMode(true);
          dispatch(
            push({
              pathOrLocation: buildRoutingPath(RoutingPath.JOURNEY, {
                fromName,
                fromId,
                toName,
                toId,
              }),
              newQueryParams: {
                [QueryParams.STEP_FREE]: stepFreeRoute
                  ? QueryParamBinary.ENABLED
                  : QueryParamBinary.DISABLED,
              },
            }),
          );
        }}
      />
    ) : null;

  const handleSwapButtonClick = () => {
    dispatch(
      push({
        pathOrLocation: buildRoutingPath(RoutingPath.NAVIGATE, {
          fromName: toName,
          fromId: toId,
          toName: fromName,
          toId: fromId,
        }),
      }),
    );
  };

  return (
    <>
      <DesktopRoutingView
        chipContainerRef={chipContainerRef}
        isFetchingRoute={isFetching}
        isRouteSuccess={isSuccess}
        routeMilestones={routeMilestones}
        totalTime={routeData?.routeMetadata[0].totalTime || 0}
        timeToDestinationComponent={
          <TimeToDestinationComponent
            showGoButton={false}
            className={styles.desktopTimeToDestination}
          />
        }
        stepFreeToggleComponent={<StepFreeToggleComponent />}
        mapLoaded={mapLoaded}
      />
      <MobileRouteView
        onSwapButtonClick={handleSwapButtonClick}
        isFetchingRoute={isFetching}
        isRouteSuccess={isSuccess}
        stepFreeToggleComponent={<StepFreeToggleComponent />}
        timeToDestinationComponent={<TimeToDestinationComponent />}
      >
        <RouteContent
          routeMilestones={routeMilestones}
          totalTime={routeData?.routeMetadata[0].totalTime || 0}
          showTime={showTime}
          displayUnit={mapData?.routing?.display_unit_system || "metric"}
          language={language}
          showStepOption={mapData?.routing?.directions_enabled}
        />
      </MobileRouteView>
    </>
  );
}
