import LivingMap, { LivingMapPlugin } from "@livingmap/core-mapping";

import FloorControl from "./floor-control";
import { Geofence, LatLng } from "../../../redux/services/types";
import GeofenceArea from "./geofences/geofence-area";

export default class GeofencePlugin extends LivingMapPlugin {
  private floorPlugin: FloorControl;
  private geofenceList: GeofenceArea[] = [];
  private triggeredGeofenceCallback: (geofenceId: string) => void = () => null;

  constructor(
    id: string,
    LMMap: LivingMap,
    floorPlugin: FloorControl,
    triggeredGeofenceCallback: (geofenceId: string) => void,
  ) {
    super(id, LMMap);
    this.LMMap = LMMap;
    this.floorPlugin = floorPlugin;
    this.triggeredGeofenceCallback = triggeredGeofenceCallback;
  }

  activate(): void {}

  deactivate(): void {}

  public setGeofences(geofences: Geofence[]): void {
    this.createGeofences(geofences);
  }

  private addGeofenceArea(geofence: Geofence): void {
    const geofenceArea = new GeofenceArea(geofence, () =>
      this.triggeredGeofenceCallback(geofence.uuid),
    );
    this.geofenceList.push(geofenceArea);
  }

  private createGeofences(geofences: Geofence[]): void {
    geofences.forEach((geofence: Geofence) => {
      const flippedGeoJson = geofence.geometry.coordinates.map(
        (coords) => coords.map((coord) => [coord[1], coord[0]]), // Swap each [lat, lon] pair
      );

      this.addGeofenceArea({
        ...geofence,
        geometry: { type: "Polygon", coordinates: flippedGeoJson },
      });
    });
  }

  public handleUserLocationForGeofences(currentUserLocation: LatLng): void {
    this.geofenceList.forEach((geofenceArea) => {
      geofenceArea.activateGeofenceIfUserIsInArea(
        currentUserLocation,
        this.floorPlugin.getActiveFloor(),
      );
    });
  }
}
