export interface LocationOptions {
  heading?: number;
  accuracy?: number;
}

export class MockGeolocationProvider implements Geolocation {
  private watchId = 1;

  private mockGeolocation:
    | ({ lat: number; lng: number } & LocationOptions)
    | null = null;

  public clearWatch(_watchId: number): void {}

  public getCurrentPosition(
    _successCallback: PositionCallback,
    _errorCallback?: PositionErrorCallback | null,
    _options?: PositionOptions,
  ): void {}

  public watchPosition(
    successCallback: PositionCallback,
    errorCallback?: PositionErrorCallback | null,
    _options?: PositionOptions,
  ): number {
    const currentWatchId = this.watchId;

    if (this.mockGeolocation)
      successCallback({
        // @ts-ignore - toJSON type error is not going to be ussed
        coords: {
          latitude: this.mockGeolocation.lat,
          longitude: this.mockGeolocation.lng,
          accuracy: this.mockGeolocation.accuracy || 1,
          heading: this.mockGeolocation.heading || null,
          altitude: null,
          altitudeAccuracy: null,
          speed: null,
        },
        timestamp: 0,
      });
    else
      errorCallback &&
        errorCallback({
          code: GeolocationPositionError.POSITION_UNAVAILABLE,
          message: "Could not get position",
        } as GeolocationPositionError);

    this.watchId++;

    return currentWatchId;
  }

  public updateMockGeolocation(
    userLocation: mapboxgl.LngLat,
    options?: LocationOptions | null,
  ): string {
    this.mockGeolocation = {
      lat: userLocation.lat,
      lng: userLocation.lng,
      ...options,
    };
    return `User location updated to: (${userLocation.lat}, ${userLocation.lng})`;
  }
}
