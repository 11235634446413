import LivingMap, {
  LayerDelegate,
  LivingMapPlugin,
  Utils,
} from "@livingmap/core-mapping";
import { FeatureCollection, Geometry, Point, Feature } from "geojson";
import { SymbolLayout } from "mapbox-gl";

import RoutingPlugin from "./routing-control";
import PositionAnimator, {
  PositionProperties,
} from "../../../utils/positionAnimator";
import { Queue } from "../../../utils/queue";
import { breakpoints } from "../../../hooks/useResponsive";
import { store } from "../../../redux/store";

const {
  createGeoJSONFeature,
  createGeoJSONFeatureCollection,
  createGeoJSONGeometryPoint,
} = Utils;

/*
  Positioning stabilisation is achieved when at least 3 positions have been received,
  with an average accuracy less than a threshold, and with the most recent position
  having an accuracy of less than 25 meters. While waiting for stabilisation, the user
  is shown an accuracy circle of the last 3 positions, centred on the most recent position.

  If stabilisation has not been achieved after either 10 received positions or 8 seconds
  since receiving the first position (whichever of these conditions occurs first), we
  consider stabilisation to have failed. At this point we show a dialog to the user
  explaining that their location can not be determined at this time. If after 8 seconds
  we have only received 2 positions, we wait another 4 seconds before failing.

  When the user receives this dialog, the accuracy circle is no longer shown. However we
  continue to run the geolocation API in the hope that given more time it will stabilise.
  The location button will reset to the inactive state, and the user can click the button
  to try again, at which point the stabilisation timeouts will reset and repeat as described
  above.

  Stabilisation is only required on mobile devices, as desktop devices give much slower
  and less accurate positions. Therefore on desktop devices we show the user's location
  as soon as we receive it, without waiting for stabilisation.
*/

const USER_LOCATION_LAYER_ID = "user-location-layer";
const USER_LOCATION_ACCURACY_ID = "user-location-accuracy-layer";
const LOCATION_TRACKING_LAYER_ID = "location-tracking-layer";
const LOCATION_TRACKING_LINE_LAYER_ID = "location-tracking-line-layer";
const USER_LOCATION_ACCURACY_SOURCE_ID = "user-location-accuracy-source";
const USER_LOCATION_SOURCE_ID = `${USER_LOCATION_LAYER_ID}-source`;
const LOCATION_TRACKING_SOURCE_ID = "location-tracking-source";
const LOCATION_TRACKING_LINE_SOURCE_ID = "location-tracking-line-source";
export const EMPTY_DATA_SOURCE: FeatureCollection<Geometry> =
  createGeoJSONFeatureCollection([]);

interface Marker {
  bearing: number | null;
  altitude: number | null;
  accuracy: number;
  latitude: number;
  longitude: number;
  floor: string;
  floorID: number;
  ts: Date;
}

interface UserLocationColour {
  r: number;
  g: number;
  b: number;
}

enum StabilisationStatus {
  UNCOMMENCED,
  STABILISING,
  STABALISED,
  FAILED,
}

export default class PositionPlugin extends LivingMapPlugin {
  protected layerDelegate: LayerDelegate;
  private routingControl: RoutingPlugin;
  private userLocationColour: UserLocationColour;
  private borderColour: string;
  private positionAnimator: PositionAnimator | null = null;
  private stabilisationStatus: StabilisationStatus;
  private stabilisationTimeoutId: NodeJS.Timeout | null = null;
  private markerQueue: Queue<Marker>;
  private stabilisationQueue: Queue<Marker>;
  private trackingQueue: Queue<Marker>;
  private headingDisplayed: boolean;
  private positionOnRoute: boolean;
  private averagedAccuracy: number;
  private debugMode: boolean;
  private isMobile: boolean;
  private lastPosition: Point | null = null;
  private onStabilisationSuccess: () => void;
  private onStabilisationFailure: () => void;

  constructor(
    id: string,
    LMMap: LivingMap,
    positionOnRoute: boolean,
    routingControl: RoutingPlugin,
    debugMode?: boolean,
    onStabilisationSuccess?: () => void,
    onStabilisationFailure?: () => void,
  ) {
    super(id, LMMap);
    this.layerDelegate = this.LMMap.getLayerDelegate();
    this.routingControl = routingControl;
    this.userLocationColour = { r: 60, g: 120, b: 255 };
    this.borderColour = "#fff";
    this.stabilisationStatus = StabilisationStatus.UNCOMMENCED;
    this.stabilisationTimeoutId = null;
    this.markerQueue = new Queue<Marker>(3);
    this.stabilisationQueue = new Queue<Marker>(11);
    this.trackingQueue = new Queue<Marker>(999);
    this.headingDisplayed = false;
    this.positionOnRoute = positionOnRoute;
    this.averagedAccuracy = 0;
    this.debugMode = debugMode || false;
    this.isMobile = window.innerWidth <= breakpoints.Mobile;
    this.onStabilisationSuccess = () => {
      if (!onStabilisationSuccess) return;
      this.stabilisationStatus = StabilisationStatus.STABALISED;
      this.updateUserLocationStyle({ displayPulse: true });
      onStabilisationSuccess();
    };
    this.onStabilisationFailure = () => {
      if (!onStabilisationFailure) return;
      this.stabilisationStatus = StabilisationStatus.FAILED;
      onStabilisationFailure();
    };
  }

  public activate(): void {
    this.createUserLocationLayer();
    this.positionAnimator = new PositionAnimator(this.LMMap.getMapboxMap());
    if (this.debugMode) this.createLocationTrackingLayer();
  }

  public deactivate = () => {
    this.removeLocationLayers();
  };

  public setMarker(location: Marker): {
    latitude: number;
    longitude: number;
  } | null {
    const oldPosition = this.getCurrentPosition();
    this.updateLocationSource(location);
    const newPosition = this.getCurrentPosition();

    if (!newPosition) return null;
    if (
      !oldPosition ||
      (oldPosition.longitude !== newPosition.longitude &&
        oldPosition.latitude !== newPosition.latitude)
    )
      return newPosition;
    return null;
  }

  public getCurrentPosition(): {
    latitude: number;
    longitude: number;
  } | null {
    if (this.lastPosition) {
      const [longitude, latitude] = this.lastPosition.coordinates;
      return { longitude, latitude };
    }
    return this.lastPosition;
  }

  public resetStabilisation() {
    this.stabilisationStatus = StabilisationStatus.STABILISING;
    this.stabilisationQueue.clear();
    if (this.stabilisationTimeoutId) {
      clearTimeout(this.stabilisationTimeoutId);
      this.stabilisationTimeoutId = null;
    }
  }

  private generateAccuracyCircle(currentPosition: Point): number {
    const currentLat = currentPosition.coordinates[1];
    const currentLong = currentPosition.coordinates[0];

    const positions = this.markerQueue.fetch();
    // finds the largest distance between the current and previous points to create a accuracy circle
    const max = positions.reduce((accumulator, actualPosition) => {
      const acLon = actualPosition.longitude;
      const acLat = actualPosition.latitude;
      // Compares two coordinates and calculate distance using pythagoras theorem
      const distance = Math.sqrt(
        (acLon - currentLong) ** 2 + (acLat - currentLat) ** 2,
      );
      return Math.max(accumulator, distance);
    }, 0);
    // increase to give output in kilometers
    return Math.max(max * 75, this.averagedAccuracy / 1.5);
  }

  private updateLocationSource(location: Marker): void {
    // Do not update location if the location is the same as the last one
    if (
      location.latitude === this.markerQueue.last?.latitude &&
      location.longitude === this.markerQueue.last?.longitude
    )
      return;

    this.trackingQueue.insert(location);
    if (this.debugMode) this.updateTrackingLayer();

    // Once stabilisation has been achieved, only use a position with accuracy greater
    // than 25 if the previous position also had an accuracy less than 25. Should help
    // to prevent fluctuations.
    if (
      this.stabilisationStatus === StabilisationStatus.STABALISED &&
      this.trackingQueue.last!.accuracy < 25 &&
      location.accuracy > 25 &&
      this.isMobile
    ) {
      return;
    }

    this.markerQueue.insert(location);
    this.stabilisationQueue.insert(location);

    const position = createGeoJSONGeometryPoint([
      location.longitude,
      location.latitude,
    ]);

    this.averagedAccuracy = this.generateAccuracyCircle(position);

    if (location.bearing) {
      if (!this.headingDisplayed) {
        // enables heading cone if bearing is supplied and not previously enabled
        this.updateUserLocationStyle({ displayPulse: true, displayCone: true });
        this.headingDisplayed = true;
      }
    } else {
      if (this.headingDisplayed) {
        // disables heading cone if bearing is not available and not already removed
        this.updateUserLocationStyle({ displayPulse: true });
        this.headingDisplayed = false;
      }
    }

    const properties: PositionProperties = {
      heading: location.bearing || 0,
      floor_id: undefined, // set to undefined so the location dot appears on all floor levels
      accuracy: this.averagedAccuracy,
    };

    if (this.positionOnRoute) {
      const routePosition =
        this.routingControl.getNearestRoutePosition(position);
      if (routePosition) {
        if (routePosition!.properties.dist) {
          properties.accuracy = routePosition!.properties.dist;
        }
        position.coordinates = routePosition!.geometry.coordinates;
      }
    }

    if (this.stabilisationStatus === StabilisationStatus.FAILED) {
      // Don't show the accuracy circle if stabilisation has failed
      properties.accuracy = 0;
    } else if (this.stabilisationStatus === StabilisationStatus.STABILISING) {
      // Set a minumum accuracy circle of 5 meters while stabilising
      properties.accuracy = Math.max(properties.accuracy, 0.003);
    }

    this.positionAnimator?.addPosition(position, properties);

    this.lastPosition = position;

    // Do not show the positioning dot until at least 3 positions have been received,
    // their averaged accuracy is less than a threshold and the most recent position
    // has an accuracy of less than 25 meters. If this has not been achieved after
    // 10 positions or 8 seconds, then stabilisation is considered to have failed. If
    // after 8 seconds we have 2 positions, we wait another 4 seconds before failing.

    const stable =
      this.averagedAccuracy < 0.002 &&
      this.markerQueue.length > 2 &&
      this.markerQueue.last!.accuracy < 25;

    if (this.stabilisationStatus === StabilisationStatus.STABILISING) {
      const {
        application: { queryParamsConfig },
      } = store.getState();
      const consoleLocation = queryParamsConfig.consoleLocation === "enable";

      // if we are mocking the location from the console we don't need to wait for stabilisation
      if (!this.isMobile || stable || consoleLocation) {
        // stabilisation has been achieved, or the user is not on a mobile device
        this.onStabilisationSuccess();
      } else if (this.stabilisationQueue.length === 10) {
        // stabilisation has not been achieved after 10 positions
        this.onStabilisationFailure();
      } else if (!this.stabilisationTimeoutId) {
        // start a single 8 second timeout
        this.stabilisationTimeoutId = setTimeout(() => {
          if (this.stabilisationStatus === StabilisationStatus.STABILISING) {
            if (this.markerQueue.length === 2) {
              // 8 seconds have passed but we have 2 positions, therefore hold on another 4 seconds
              if (this.stabilisationTimeoutId) {
                clearTimeout(this.stabilisationTimeoutId);

                // start a 3 second timeout
                this.stabilisationTimeoutId = setTimeout(() => {
                  if (
                    this.stabilisationStatus === StabilisationStatus.STABILISING
                  ) {
                    // stabilisation has not been achieved after a further 4 seconds
                    this.onStabilisationFailure();
                  }
                }, 4000);
              }
            } else {
              // stabilisation has not been achieved after 8 seconds
              this.onStabilisationFailure();
            }
          }
        }, 8000);
      }
    }
  }

  private layerNotDefined = (layerId: string) => {
    return !this.layerDelegate.getLayer(layerId);
  };

  private sourceNotDefined = (sourceId: string) => {
    return !this.LMMap.getMapboxMap().getSource(sourceId);
  };

  /**
   *
   * @param hex a hex colour for the user location dot
   * @param borderColour a border colour for the dot
   * @param displayPulse whether to display a pulsing animation
   */
  private updateUserLocationStyle = ({
    displayPulse = false,
    displayCone = false,
  }: {
    displayPulse?: boolean;
    displayCone?: boolean;
  }) => {
    if (this.stabilisationStatus !== StabilisationStatus.STABALISED) return;

    if (this.LMMap.getMapboxMap().hasImage("location-dot"))
      this.LMMap.getMapboxMap().removeImage("location-dot");

    this.LMMap.getMapboxMap().addImage(
      "location-dot",
      this.createLocationDot({ displayPulse, displayCone }),
      { pixelRatio: 1.33 },
    );
  };

  private createUserLocationLayer(): void {
    const userLocationLayerDoesNotExist = this.layerNotDefined(
      USER_LOCATION_LAYER_ID,
    );
    if (userLocationLayerDoesNotExist) {
      this.layerDelegate.addSource(USER_LOCATION_SOURCE_ID, {
        type: "geojson",
        data: EMPTY_DATA_SOURCE,
      });
    }

    try {
      this.layerDelegate.addSource(USER_LOCATION_ACCURACY_SOURCE_ID, {
        type: "geojson",
        data: EMPTY_DATA_SOURCE,
      });
    } catch (error) {} // already exists ignore

    const doesLayerNotExist = this.layerNotDefined(USER_LOCATION_LAYER_ID);
    if (doesLayerNotExist) {
      this.layerDelegate.addLayer({
        id: USER_LOCATION_ACCURACY_ID,
        type: "fill",
        source: USER_LOCATION_ACCURACY_SOURCE_ID,
        paint: {
          "fill-color": "rgba(91, 148, 198, 0.3)",
        },
      });

      const layerLayout: SymbolLayout = {
        "icon-image": "location-dot",
        "icon-offset": [0, 0],
        "icon-allow-overlap": true,
        "icon-rotate": ["get", "heading"],
        "icon-rotation-alignment": "map",
      };

      this.layerDelegate.addLayer({
        id: USER_LOCATION_LAYER_ID,
        type: "symbol",
        source: USER_LOCATION_SOURCE_ID,
        layout: layerLayout,
      });
    }
  }

  private createLocationTrackingLayer(): void {
    const trackingSourceUndefined = this.sourceNotDefined(
      LOCATION_TRACKING_SOURCE_ID,
    );

    if (trackingSourceUndefined) {
      this.layerDelegate.addSource(LOCATION_TRACKING_SOURCE_ID, {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
    }

    const trackingLayerUndefined = this.layerNotDefined(
      LOCATION_TRACKING_LAYER_ID,
    );

    if (trackingLayerUndefined) {
      this.layerDelegate.addLayer({
        id: LOCATION_TRACKING_LAYER_ID,
        source: LOCATION_TRACKING_SOURCE_ID,
        type: "circle",
        paint: {
          "circle-color": ["get", "colour"],
          "circle-stroke-width": ["get", "strokeWidth"],
          "circle-radius": 3,
        },
      });
    }

    const lineSourceUndefined = this.sourceNotDefined(
      LOCATION_TRACKING_LINE_SOURCE_ID,
    );

    if (lineSourceUndefined) {
      this.layerDelegate.addSource(LOCATION_TRACKING_LINE_SOURCE_ID, {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
    }

    const lineLayerUndefined = this.layerNotDefined(
      LOCATION_TRACKING_LINE_LAYER_ID,
    );

    if (lineLayerUndefined) {
      this.layerDelegate.addLayer({
        id: LOCATION_TRACKING_LINE_LAYER_ID,
        source: LOCATION_TRACKING_LINE_SOURCE_ID,
        type: "line",
        paint: { "line-color": "grey" },
      });
    }

    console.warn(
      `Location tracking layer created. Raw positioning data will be displayed on the map. The colour of a position is determined by the accuracy of the position. %cBlue%c is most accurate, then %cgreen%c, %cyellow%c, %corange%c, %cred%c; and %cblack%c is least accurate. Positions with an altitude (i.e. outdoor positions) will have a %cthicker stroke width%c.`,
      ...[
        ...["color: #577590", ""],
        ...["color: #76c893", ""],
        ...["color: #f9c74f", ""],
        ...["color: #f67c2d", ""],
        ...["color: #da1e28", ""],
        ...["color: #000000", ""],
        ...["font-weight: bold;", ""],
      ],
    );
  }

  private updateTrackingLayer(): void {
    const positions = this.trackingQueue.fetch();

    const pointFeatures = positions.map((position) => {
      return createGeoJSONFeature(
        {
          colour: this.determinePositionColourFromAccuracy(position),
          strokeWidth: position.altitude !== null ? 2 : 0.5,
        },
        { type: "Point", coordinates: [position.longitude, position.latitude] },
      );
    });

    const pointFeatureCollection =
      createGeoJSONFeatureCollection(pointFeatures);

    const trackingSource = this.layerDelegate?.getSourceProxy(
      LOCATION_TRACKING_SOURCE_ID,
    );

    if (trackingSource) {
      trackingSource.setData(pointFeatureCollection);
    }

    const lineFeatures = positions.reduce((acc: Feature[], position, index) => {
      if (index === 0) return acc;

      const feature = createGeoJSONFeature(
        {},
        {
          type: "LineString",
          coordinates: [
            [positions[index - 1].longitude, positions[index - 1].latitude],
            [position.longitude, position.latitude],
          ],
        },
      );

      return [...acc, feature];
    }, []);

    const lineFeatureCollection = createGeoJSONFeatureCollection(lineFeatures);

    const lineSource = this.layerDelegate?.getSourceProxy(
      LOCATION_TRACKING_LINE_SOURCE_ID,
    );

    if (lineSource) {
      lineSource.setData(lineFeatureCollection);
    }
  }

  private removeLocationLayers() {
    this.layerDelegate.removeLayer(USER_LOCATION_LAYER_ID);
    this.layerDelegate.removeLayer(USER_LOCATION_ACCURACY_ID);
    this.layerDelegate.removeLayer(LOCATION_TRACKING_LAYER_ID);
    this.layerDelegate.removeLayer(LOCATION_TRACKING_LINE_LAYER_ID);
    this.layerDelegate.removeSource(USER_LOCATION_SOURCE_ID);
    this.layerDelegate.removeSource(USER_LOCATION_ACCURACY_SOURCE_ID);
    this.layerDelegate.removeSource(LOCATION_TRACKING_SOURCE_ID);
    this.layerDelegate.removeSource(LOCATION_TRACKING_LINE_SOURCE_ID);
  }

  private determinePositionColourFromAccuracy(position: Marker) {
    const { accuracy } = position;

    if (accuracy < 1) return "#4a6572";
    if (accuracy < 2) return "#577590";
    if (accuracy < 3) return "#5e8b9d";
    if (accuracy < 4) return "#6591aa";
    if (accuracy < 5) return "#4d908e";
    if (accuracy < 6) return "#43aa8b";
    if (accuracy < 7) return "#76c893";
    if (accuracy < 8) return "#90be6d";
    if (accuracy < 9) return "#a9d77e";
    if (accuracy < 10) return "#c6e18b";
    if (accuracy < 11) return "#e3e965";
    if (accuracy < 12) return "#f9c74f";
    if (accuracy < 13) return "#f9b131";
    if (accuracy < 14) return "#f8961e";
    if (accuracy < 15) return "#f67c2d";
    if (accuracy < 16) return "#f3722c ";
    if (accuracy < 17) return "#f94144";
    if (accuracy < 18) return "#e63946";
    if (accuracy < 19) return "#da1e28";
    if (accuracy < 20) return "#c9184a";
    if (accuracy < 22) return "#a52a2a";
    if (accuracy < 24) return "#6e260e";
    return "#000000";
  }

  private createLocationDot({
    displayPulse,
    displayCone,
  }: {
    displayPulse: boolean;
    displayCone: boolean;
  }) {
    const diameter = 80;
    const radius = diameter / 2;
    const borderWidth = 3.5;
    const frameDuration = 120;
    const maxBorderWidth = borderWidth * 1.4;

    /**
     * Modified version of code referenced from https://docs.mapbox.com/mapbox-gl-js/example/add-image-animated/
     */
    const locationDot = {
      width: diameter,
      height: diameter,
      radius: radius * 0.3,
      borderWidth: borderWidth,
      data: new Uint8Array(diameter * diameter * 4),
      context: null as CanvasRenderingContext2D | null,
      lmMap: this.LMMap,
      userLocationColour: this.userLocationColour,
      borderColour: this.borderColour,
      frame: 0,

      // When the layer is added to the map,
      // get the rendering context for the map canvas.
      onAdd: function () {
        const canvas = document.createElement("canvas");
        canvas.width = diameter;
        canvas.height = diameter;
        this.context = canvas.getContext("2d");
      },

      // Call once before every frame where the icon will be used.
      render: function () {
        const context = this.context;

        const toRadians = (deg: number) => (deg * Math.PI) / 180;

        context!.clearRect(0, 0, diameter, diameter);

        if (displayPulse) {
          this.frame += 1;

          let currentBorderWidth = borderWidth;

          if (this.frame <= frameDuration) {
            // Hold phase
            currentBorderWidth = borderWidth;
          } else if (this.frame <= frameDuration * 2) {
            // Shrink phase
            const t = (this.frame - frameDuration) / frameDuration;
            const easedT = Math.pow(t, 3);
            currentBorderWidth =
              borderWidth + (maxBorderWidth - borderWidth) * easedT;
          } else if (this.frame <= frameDuration * 3) {
            // Grow phase
            const t = (this.frame - frameDuration * 2) / frameDuration;
            const easedT = 1 - Math.pow(1 - t, 3);
            currentBorderWidth =
              maxBorderWidth - (maxBorderWidth - borderWidth) * easedT;
          }

          if (this.frame > frameDuration * 3) {
            this.frame = 0; // Reset the frame count after one full cycle
          }

          this.borderWidth = currentBorderWidth;
          this.radius = radius * 0.3 - (currentBorderWidth - borderWidth) / 2;
        }

        // Draw the directional cone
        if (displayCone) {
          context!.fillStyle = `rgba(${this.userLocationColour.r}, ${this.userLocationColour.g}, ${this.userLocationColour.b}, 0.4)`;
          context!.beginPath();
          context!.moveTo(radius, radius);
          context!.arc(radius, radius, radius, toRadians(240), toRadians(300));
          context!.lineTo(radius, radius);
          context!.closePath();
          context!.fill();
        }

        // Draw the inner circle with adjusted radius.
        context!.beginPath();
        context!.arc(radius, radius, this.radius, 0, Math.PI * 2);
        context!.fillStyle = `rgba(${this.userLocationColour.r}, ${this.userLocationColour.g}, ${this.userLocationColour.b}, 1)`;
        context!.fill();

        // Draw the border with the updated border width.
        context!.strokeStyle = this.borderColour;
        context!.lineWidth = this.borderWidth;
        context!.shadowOffsetX = 0;
        context!.shadowOffsetY = 0;
        context!.shadowBlur = 8;
        context!.shadowColor = "rgba(0, 0, 0, 0.3)";
        context!.stroke();

        // Convert Uint8ClampedArray to Uint8Array
        const imageData = context!.getImageData(0, 0, diameter, diameter).data;
        // Update this image's data with data from the canvas.
        this.data = new Uint8Array(imageData.buffer);

        // Continuously repaint the map, resulting
        // in the smooth animation of the dot.
        this.lmMap.getMapboxMap().triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
      },
    };

    return locationDot;
  }
}
