import { useParams } from "react-router-dom";
import { IconType } from "@livingmap/core-ui-v2";
import { addMinutes, format } from "date-fns";

import {
  DisplayUnitSystem,
  RouteMilestoneFeature,
} from "./../redux/services/types/index";
import { useMatchLocationCoords } from "./useMatchLocationCoords";
import { formatLatLng } from "../utils/formatLatLng";
import i18n from "../i18next";
import { useTranslation } from "react-i18next";

interface Props {
  routeMilestones: RouteMilestoneFeature[];
  totalTime?: number;
  displaySteps: boolean;
  displayUnit: DisplayUnitSystem;
  language: string;
}

const getSubText = (type: string, direction?: string) => {
  switch (type) {
    case "floor_change":
      if (direction === "up") return i18n.t("navigate_page.go_up");
      else return i18n.t("navigate_page.go_down");
    case "route_start":
      return i18n.t("navigate_page.start_at");
    case "route_end":
      return i18n.t("navigate_page.arrive_at");
    case "direction":
      return "";
    default:
      return i18n.t("navigate_page.navigate_default");
  }
};

const getIcon = (
  mapIcon: string,
  type: string,
  direction?: string,
): IconType => {
  if (type === "direction") {
    switch (direction) {
      case "left":
        return "LeftShortArrowIcon";
      case "right":
        return "RightShortArrowIcon";
      case "forward":
        return "UpShortArrowIcon";
      default:
        return "WalkingPersonIcon";
    }
  } else {
    switch (mapIcon) {
      case "route_stairs_go_up":
      case "route_stairs_go_down":
        return "StairsIcon";
      case "route_lift_go_up":
      case "route_lift_go_down":
        return "LiftIcon";
      case "route_escalator_go_up":
      case "route_escalator_go_down":
        return "EscalatorIcon";
      case "route_start":
        return "NearMeTopIcon";
      case "route_end":
        return "LocationIcon";
      default:
        return "WalkingPersonIcon";
    }
  }
};

const getText = (
  descriptionText: string | undefined,
  type: string,
  displayUnit: DisplayUnitSystem,
  landmark: string | undefined,
  direction?: string,
  distance?: number,
): string => {
  if (type === "direction") {
    switch (direction) {
      case "left":
        return [i18n.t("navigate_page.turn_left"), landmark]
          .filter(Boolean)
          .join(", ");
      case "right":
        return [i18n.t("navigate_page.turn_right"), landmark]
          .filter(Boolean)
          .join(", ");
      case "forward":
        const displayDistance = convertDistance(distance!, displayUnit);
        return [
          `${i18n.t("navigate_page.head_straight")} (${displayDistance})`,
          landmark,
        ]
          .filter(Boolean)
          .join(", ");
      default:
        return descriptionText ?? "";
    }
  } else {
    return descriptionText ?? "";
  }
};

const getFeatureName = (
  language: string,
  featureLanguageObj?: any,
): string | undefined => {
  if (featureLanguageObj) {
    try {
      const name = featureLanguageObj[language];
      return name;
    } catch (err) {}
  }
  return;
};

const convertDistance = (
  meters: number,
  displayUnit: DisplayUnitSystem,
): string => {
  if (displayUnit === "imperial") {
    const feet = Math.round(meters * 3.281);
    // If less than 0.2 miles, show feet
    if (feet <= 1056) return `${feet} ft`;
    else {
      // If more than 0.2 miles, convert and show miles
      const miles = (meters * 1609).toFixed(1);
      return `${miles} mi`;
    }
  } else {
    // If less than 1km, show meters
    if (meters < 1) return "<1 m";
    if (meters < 1000) return `${meters} m`;
    else return `${meters.toFixed(1)} m`;
  }
};

export const useGetRouteMilestonesProps = ({
  routeMilestones,
  totalTime = 0,
  displaySteps,
  displayUnit,
  language,
}: Props) => {
  const { fromId, toId } = useParams();
  const { t } = useTranslation();

  const { isMatchWithLocationCoords } = useMatchLocationCoords();

  const fromCoords = fromId?.split(",");
  const toCoords = toId?.split(",");

  return routeMilestones
    .filter(({ properties: { type } }) => {
      if (type === "route_start" || type === "route_end") {
        return true;
      } else {
        return displaySteps;
      }
    })
    .map(
      (
        {
          properties: {
            descriptionText,
            mapIcon,
            type,
            direction,
            length,
            landmarkName,
          },
          geometry: { coordinates },
        },
        index,
      ) => {
        const cleanText =
          typeof descriptionText === "string"
            ? descriptionText
            : getFeatureName(language, descriptionText);

        let coords: string[] | undefined;
        if (cleanText === "Start") coords = fromCoords;
        else if (cleanText === "End") coords = toCoords;

        const landmark = getFeatureName(language, landmarkName);
        const accent = routeMilestones.length === index + 1 || index === 0;
        const text = isMatchWithLocationCoords(coords)
          ? t("route_page.your_location_button")
          : coords?.length === 2
            ? formatLatLng(coordinates)
            : getText(
                cleanText,
                type,
                displayUnit,
                landmark,
                direction,
                length,
              );
        const subText =
          cleanText === "Start" ? "" : getSubText(type, direction);
        const icon =
          cleanText === "Start"
            ? "NearMeIcon"
            : getIcon(mapIcon, type, direction);

        const time =
          routeMilestones.length === index + 1
            ? format(addMinutes(new Date(), totalTime), "HH:mm")
            : ""; // TODO: '' to be time between milestones in the future

        return {
          accent,
          text,
          subText,
          icon,
          time,
        };
      },
    );
};
