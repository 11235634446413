import {
  RouteMilestoneFeature,
  RouteQueryResponse,
} from "./../redux/services/types/index";

export const generateFullRouteMilestones = (
  sequenceOrder: number[],
  segments: RouteQueryResponse["segments"],
  directionsEnabled?: boolean,
) => {
  let features: RouteMilestoneFeature[] = [];

  sequenceOrder.forEach((segmentNum) => {
    const segment = segments[segmentNum];
    features = features.concat(segment.routeMilestones?.features || []);
  });

  if (!directionsEnabled) {
    return features.filter(({ properties: { type } }) => {
      return type !== "direction";
    });
  }
  return features;
};
