import { useState } from "react";
import { useTranslation } from "react-i18next";

import { setLocationStatus } from "../redux/slices/applicationSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useGetMapConfigQuery } from "../redux/services/vectormapsAPI";
import { LatLng } from "../redux/services/types";

import { initialiseLocatorDebugTools } from "../utils/locator-debugging/initialise-locator-debug-tools";
import { initialiseGeolocationDebugger } from "../utils/geolocation-debugging/initialise-geolocation-debugger";

import { LocationStatus } from "../components/LocationButton/LocationButton";

enum LocationPermissionStatus {
  GRANTED = "granted",
  DENIED = "denied",
  PROMPT = "prompt",
}

export default function useLocationPermission({
  setLocationMarker,
  handleUserWithinGeofence,
  mockGeolocationCallback,
}: {
  setLocationMarker: ({
    latitude,
    longitude,
    bearing,
    altitude,
    accuracy,
  }: {
    latitude: number;
    longitude: number;
    bearing: number | null;
    altitude: number | null;
    accuracy: number;
  }) => void;
  handleUserWithinGeofence: ({ latitude, longitude }: LatLng) => void;
  mockGeolocationCallback: () => void;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { queryParamsConfig } = useAppSelector((state) => state.application);
  const [promptInitialised, setPromptInitialised] = useState(false);

  const [locatorLogger, locatorAssetReplayGeolocation] =
    initialiseLocatorDebugTools();

  const geolocationMock = queryParamsConfig.consoleLocation
    ? initialiseGeolocationDebugger(mockGeolocationCallback)
    : null;

  // if we have a geolocation mock provider or locator geolocation provider, use that instead of the browser geolocation,
  // the geolocation mock provider is for location debugging and the locator geolocation provider is for asset debugging
  // and will replay an assets pings as if they were the users location at fixed intervals
  const geolocation: Geolocation =
    geolocationMock || locatorAssetReplayGeolocation || navigator.geolocation;

  let watchId: number;

  const { data: mapData } = useGetMapConfigQuery();

  const clearUserLocationWatch = () => {
    if (geolocation && watchId) {
      geolocation.clearWatch(watchId);
    }
  };

  const requestUserLocation = () => {
    // if we are in console location mode, we don't need to show the searching status as we are setting the location manually
    if (!queryParamsConfig.consoleLocation)
      dispatch(setLocationStatus(LocationStatus.SEARCHING));

    if (geolocation) {
      watchId = geolocation.watchPosition(
        handleUserLocationSuccess,
        handleUserLocationFailed,
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
      );
    }
  };

  const handlePermissions = (state: PermissionState) => {
    switch (state) {
      case LocationPermissionStatus.PROMPT:
        requestUserLocation();
        setPromptInitialised(true);
        break;
      case LocationPermissionStatus.GRANTED:
        requestUserLocation();
        break;
      case LocationPermissionStatus.DENIED: // this state seems to only happen on chrome
        alertDeclinedLocationPermission();
        dispatch(setLocationStatus(LocationStatus.DISABLED));
        break;
      default:
        break;
    }
  };

  const fallbackGeolocationPermissionCheck = () => {
    if (!geolocation) {
      console.error("Geolocation is not supported by your browser.");
      return;
    }

    geolocation.getCurrentPosition(
      () => {
        handlePermissions("granted");
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          handlePermissions("denied");
        } else {
          console.error("Geolocation error:", error);
        }
      },
    );
  };

  const handleLocationRequest = async () => {
    try {
      if ("permissions" in navigator && "query" in navigator.permissions) {
        const { state } = await navigator.permissions.query({
          name: "geolocation",
        });
        handlePermissions(state);
      } else {
        fallbackGeolocationPermissionCheck();
      }
    } catch (error) {
      fallbackGeolocationPermissionCheck();
    }
  };

  const handleUserLocationSuccess: PositionCallback = (position) => {
    const {
      coords: { latitude, longitude, heading, altitude, accuracy },
    } = position;
    const bearing = heading;

    locatorLogger &&
      locatorLogger.log(position).catch((e) => {
        console.error(e);
        console.error("locator log failed");
      });

    if (
      watchId &&
      mapData &&
      (longitude < mapData.extents.bottom_left.longitude ||
        longitude > mapData.extents.top_right.longitude ||
        latitude < mapData.extents.bottom_left.latitude ||
        latitude > mapData.extents.top_right.latitude)
    ) {
      clearUserLocationWatch();
      alert(t("home.user_location_out_of_bounds"));
      dispatch(setLocationStatus(LocationStatus.INACTIVE));
      return;
    }

    setLocationMarker &&
      setLocationMarker({ latitude, longitude, bearing, altitude, accuracy });

    handleUserWithinGeofence &&
      handleUserWithinGeofence({ latitude, longitude });
  };

  const handleUserLocationFailed: PositionErrorCallback = (error) => {
    if (promptInitialised && error.code === error.PERMISSION_DENIED)
      alertDeclinedLocationPermission();

    dispatch(setLocationStatus(LocationStatus.DISABLED));
  };

  const alertDeclinedLocationPermission = () =>
    alert(t("home.user_location_declined_alert"));

  return {
    handleLocationRequest,
  };
}
