import classNames from "classnames";
import { Icon } from "@livingmap/hippo";

import styles from "./CompassButton.module.scss";

interface Props {
  dataQA: string;
  className?: string;
  bearing: number;
  theme?: "light" | "dark";
  size?: "small" | "medium" | "large";
  onClick: () => void;
}

const CompassButton: React.FC<Props> = ({
  dataQA,
  bearing,
  className,
  theme = "light",
  size = "small",
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(className, {
        [styles.container]: true,
        [styles[size]]: true,
        [styles[theme]]: true,
      })}
    >
      <div
        style={{
          display: "flex",
          transform: `rotate(${-bearing}deg)`,
        }}
      >
        <Icon dataQA={dataQA} name="compass" size={24} className={styles.svg} />
      </div>
    </div>
  );
};

export default CompassButton;
